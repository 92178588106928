// quiz_fin_popup.js
import { Modal, Button } from "react-bootstrap";

const DisplayModalWithResults = ({
  currQuiz,
  state,
  handleQuizState,
  quiz_name,
}) => {
  return (
    <>
      <Modal show={state} onHide={() => handleQuizState()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h3>
              {quiz_name} - <small className="text-muted">Quiz Summary</small>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th>Quiz Number</th>
                <th>Total Questions</th>
                <th>Correct Answers</th>
                <th>Incorrect Answers</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currQuiz[0].gameIndex}</td>
                <td>{currQuiz[0].totalQuestions}</td>
                <td className="table-success">{currQuiz[0].correctAnswers}</td>
                <td className="table-danger">
                  {currQuiz[0].incorrectResponses.length}
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => handleQuizState()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisplayModalWithResults;
