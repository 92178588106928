const informationRoadsignQuestions = [
  {
    roadSignOption: require("./roadsigns/information_road_signs/dublin_port_tunnel_ahead.png"),
    answerOptions: [
      {
        answerText: "Distance sign",
        isCorrect: false,
      },
      {
        answerText: "Dublin port tunnel ahead",
        isCorrect: true,
      },
      {
        answerText: "Tunnel ahead",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/lane_destination_sign.png"),
    answerOptions: [
      {
        answerText: "Regional road",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Lan direction sign",
        isCorrect: true,
      },
      {
        answerText: "Destination direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/destination_distance_sign.png"),
    answerOptions: [
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Town or village sign",
        isCorrect: false,
      },
      {
        answerText: "Direction and distance",
        isCorrect: false,
      },
      {
        answerText: "Destination distance sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/sos_lay-by.png"),
    answerOptions: [
      {
        answerText: "Lay-by ahead",
        isCorrect: false,
      },
      {
        answerText: "SOS lay-by",
        isCorrect: true,
      },
      {
        answerText: "Speed camera",
        isCorrect: false,
      },
      {
        answerText: "Hospital ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/speedcamera_ahead.jpg"),
    answerOptions: [
      {
        answerText: "Hospital ahead",
        isCorrect: false,
      },
      {
        answerText: "SOS lay-by",
        isCorrect: false,
      },
      {
        answerText: "Speed camera",
        isCorrect: true,
      },
      {
        answerText: "Lay-by ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advanced_information_sign_for_low_clearance.png"),
    answerOptions: [
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
      {
        answerText: "Advanced information sign for low clearance",
        isCorrect: true,
      },
      {
        answerText: "Tunnel ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/traffic_calming_sign.png"),
    answerOptions: [
      {
        answerText: "Slow",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "School children crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/traffic_calming_sign1.jpg"),
    answerOptions: [
      {
        answerText: "Speed limit change ahead",
        isCorrect: false,
      },
      {
        answerText: "Speed camera ahead",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: true,
      },
      {
        answerText: "School children crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/supplementary_plate.jpg"),
    answerOptions: [
      {
        answerText: "Supplementary plate",
        isCorrect: true,
      },
      {
        answerText: "Speed limit change ahead",
        isCorrect: false,
      },
      {
        answerText: "Lay-by",
        isCorrect: false,
      },
      {
        answerText: "Slow lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/ramps.png"),
    answerOptions: [
      {
        answerText: "Ramps on road",
        isCorrect: true,
      },
      {
        answerText: "Speed limit change ahead",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: false,
      },
      {
        answerText: "Slow lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advance_sign_lay-by_tourism_facilities.png"),
    answerOptions: [
      {
        answerText: "Advance sign for lay-by with tourism information",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance sign for facilities in lay-by",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advance_direction_to_local_services.png"),
    answerOptions: [
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance sign for facilities in lay-by",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: true,
      },
      {
        answerText: "Alternative route off motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/sign_to_approved_tourist_information_points.png"),
    answerOptions: [
      {
        answerText: "Tourist attraction direction sign",
        isCorrect: false,
      },
      {
        answerText: "Sign to approved tourist information points",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/tourist_advanced_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Sign to approved tourist information",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/pedestrian_sign_to_tourist_attraction.jpg"),
    answerOptions: [
      {
        answerText: "Sign to approved tourist information",
        isCorrect: false,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/alternative_route_off_motorway.jpg"),
    answerOptions: [
      {
        answerText: "Alternative route off the motorway",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/tourist_attraction_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Sign to approved tourist information",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Tourist attraction direction sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/signing_to_approved_tourist_information.png"),
    answerOptions: [
      {
        answerText: "Tourist attraction direction sign",
        isCorrect: false,
      },
      {
        answerText: "Sign to approved tourist information points",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/sign_for_oige_youth_hostels.png"),
    answerOptions: [
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
      {
        answerText: "Sign for Óige youth hostels",
        isCorrect: true,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advance_sign_facilities_in_lay-by.png"),
    answerOptions: [
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance sign for facilities in lay-by",
        isCorrect: true,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
      {
        answerText: "Alternative route off motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/alternative_route_heavy_vehicles.png"),
    answerOptions: [
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: true,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/alternative_route_high_vehicles.png"),
    answerOptions: [
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: true,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: false,
      },
      {
        answerText: "Height restriction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/speed_limit_ahead.jpg"),
    answerOptions: [
      {
        answerText: "Speed limit ahead",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Slow",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/town_or_village_sign.png"),
    answerOptions: [
      {
        answerText: "Town or village sign",
        isCorrect: true,
      },
      {
        answerText: "Destination distance sign",
        isCorrect: false,
      },
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/slow_lane_sign.png"),
    answerOptions: [
      {
        answerText: "Slow lane sign",
        isCorrect: true,
      },
      {
        answerText: "Lane Loss",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/cul_de_sac.png"),
    answerOptions: [
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Lane Loss",
        isCorrect: false,
      },
      {
        answerText: "Cul-de-sac",
        isCorrect: true,
      },
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/industrial_estate.png"),
    answerOptions: [
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Lane Loss",
        isCorrect: false,
      },
      {
        answerText: "Industrial estate symbol",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/disabled_persons_parking.png"),
    answerOptions: [
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Disabled persons parking bay",
        isCorrect: true,
      },
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/airport.png"),
    answerOptions: [
      {
        answerText: "Airport symbol",
        isCorrect: true,
      },
      {
        answerText: "Disabled persons parking bay",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/hospital_ahead.png"),
    answerOptions: [
      {
        answerText: "Lay-by ahead sign",
        isCorrect: false,
      },
      {
        answerText: "Hospital ahead",
        isCorrect: true,
      },
      {
        answerText: "Hospital",
        isCorrect: false,
      },
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/hospital_sign.png"),
    answerOptions: [
      {
        answerText: "Lay-by ahead sign",
        isCorrect: false,
      },
      {
        answerText: "Hospital ahead",
        isCorrect: false,
      },
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
      {
        answerText: "Hospital",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/lay-by_ahead_sign.png"),
    answerOptions: [
      {
        answerText: "SOS lay-by",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Advanced sign for lay-by with tourism information",
        isCorrect: false,
      },
      {
        answerText: "Lay-by ahead sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/lay_by.png"),
    answerOptions: [
      {
        answerText: "Advanced sign for lay-by with tourism information",
        isCorrect: false,
      },
      {
        answerText: "Lay-by",
        isCorrect: false,
      },
      {
        answerText: "SOS lay-by",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/ferry_symbol.png"),
    answerOptions: [
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
      {
        answerText: "Ferry symbol",
        isCorrect: true,
      },
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/local_road_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: true,
      },
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/regional_road_direction_sign.png"),
    answerOptions: [
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: true,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/motorway_sign1.png"),
    answerOptions: [
      {
        answerText: "Advanced direction sign",
        isCorrect: true,
      },
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "Motorway ahead",
        isCorrect: false,
      },
      {
        answerText: "Regional road",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/national_road.png"),
    answerOptions: [
      {
        answerText: "National road",
        isCorrect: true,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Regional road",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/national_road_small.png"),
    answerOptions: [
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "National road",
        isCorrect: true,
      },
      {
        answerText: "Regional road",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/regional_road.png"),
    answerOptions: [
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Regional road",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/motorway_direction_sign.jpg"),
    answerOptions: [
      {
        answerText: "Motorway direction sign",
        isCorrect: true,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/national_road_direction_signs.png"),
    answerOptions: [
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "National road direction sign",
        isCorrect: true,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway direction sign",
        isCorrect: false,
      },
    ],
  },
];

export default informationRoadsignQuestions;
