import NavBar from "./navbar";
import React, { useState, useEffect } from "react";
import roundaboutAhead from "./roadsigns/warning_road_signs/roundabout_ahead.png";
import roadworksahead from "./roadsigns/construction_signs/roadworks_ahead.png";
import motorwaysign from "./roadsigns/transparent-motorway_sign.png";
import no_straight_ahead from "./roadsigns/regulatory_signs/no_straight_ahead.png";
import hospital_ahead from "./roadsigns/information_road_signs/hospital_ahead.png";
import signing_to_approved_tourist_information from "./roadsigns/information_road_signs/signing_to_approved_tourist_information.png";
import { shuffle } from "lodash";
import DisplayModalWithResults from "./quiz_fin_popup";
import Question from "./Question";
import ResultTable from "./results_table";
import DisplayResults from "./display_results";
import questions from "./yellow_sign_questions";
import construction_questions from "./construction_sign_questions";
import allQuestions from "./all_questions";
import motorwayQuestions from "./motorway_sign_questions";
import regulatoryQuestions from "./regulatory_sign_questions";
import informationRoadsignQuestions from "./information_road_signs";
import { ContactUs } from "./contact_us";
import { Disclaimer } from "./disclaimer";
import { Analytics } from "@vercel/analytics/react";
import { Toaster } from "react-hot-toast";

function App() {
  const [allGameResults, setAllGameResults] = useState([]);
  const [currGameResults, setCurrGameResults] = useState([]);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [incorrectResponses, setIncorrectResponses] = useState([]);
  const [correctResponses, setCorrectResponses] = useState([]);
  const [selectedGameIndex, setSelectedGameIndex] = useState(null);
  const [viewOption, setViewOption] = useState(1);
  const [quizFinished, setQuizFinished] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [quizType, setQuizType] = useState("");
  const [feedbackFormState, setFeedbackFormState] = useState(false);
  const [disclaimerState, setDisclaimerState] = useState(false);

  const handleRestartQuiz = () => {
    setQuizStarted(false);
    setCurrentQuestionIndex(0);
    setCorrectAnswersCount(0);
    setIncorrectResponses([]);
    setCorrectResponses([]);
  };

  useEffect(() => {
    console.log("Total Incorrect: " + incorrectResponses.length);
    console.log("Total correct: " + correctResponses.length);
  }, [incorrectResponses, correctResponses]);

  const handleSelectAnswer = (selectedAnswer) => {
    const currentQuestion = shuffledQuestions[currentQuestionIndex];
    const isCorrect = currentQuestion.answerOptions[selectedAnswer].isCorrect;

    if (!currentQuestion) {
      return; // exit early if currentQuestion is not defined
    }

    if (isCorrect) {
      // Handle correct answer feedback
      setCorrectAnswersCount((prevCount) => prevCount + 1);
      //console.log("Correct answer!");
      const correctResponse = {
        question: currentQuestion.roadSignOption,
        selectedAnswer:
          currentQuestion.answerOptions[selectedAnswer].answerText,
        correctAnswer: currentQuestion.answerOptions.find(
          (option) => option.isCorrect
        ).answerText,
      };

      setCorrectResponses((prevResponses) => [
        ...prevResponses,
        correctResponse,
      ]);
      console.log(
        "Saving Correct answer! - " +
          correctResponse.selectedAnswer +
          " -- " +
          currentQuestionIndex
      );
    } else {
      // Handle incorrect answer feedback
      // Save the incorrect question and answer
      const incorrectResponse = {
        question: currentQuestion.roadSignOption,
        selectedAnswer:
          currentQuestion.answerOptions[selectedAnswer].answerText,
        correctAnswer: currentQuestion.answerOptions.find(
          (option) => option.isCorrect
        ).answerText,
      };

      //console.log("Incorrect Response:", incorrectResponse);

      setIncorrectResponses((prevResponses) => [
        ...prevResponses,
        incorrectResponse,
      ]);
      console.log(
        "Saving Incorrect answer! - " +
          incorrectResponse.selectedAnswer +
          " -- " +
          currentQuestionIndex
      );
    }

    const nextQuestion = currentQuestionIndex + 1;
    // Move to the next question
    console.log(
      "Next question: " +
        nextQuestion +
        ", Shuffled Q lenght: " +
        shuffledQuestions.length
    );
    if (nextQuestion < shuffledQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setGameOver(true);
    }
  };

  // Define a useEffect hook to create the gameResult object when state variables change
  useEffect(() => {
    // Ensure that correctAnswersCount, shuffledQuestions, incorrectResponses, and correctResponses are defined
    if (gameOver === true) {
      // Create the gameResult object after state variables are updated
      const gameResult = {
        // eslint-disable-next-line
        gameIndex: allGameResults.length + 1, // Game index starts from 1
        correctAnswers: correctAnswersCount,
        totalQuestions: shuffledQuestions.length,
        incorrectResponses: [...incorrectResponses],
        correctResponses: [...correctResponses],
      };
      // Update currGameResults state with the gameResult object
      setCurrGameResults([gameResult]);
      console.log("--------");
      console.log(gameResult);
      console.log("--------");

      // Save the current game result to the overall results array
      setAllGameResults((prevGames) => [...prevGames, gameResult]);

      console.log(
        `The Quiz is finished! You got ${correctAnswersCount} correct!`
      );
      console.log(`Games played ${allGameResults.length}`);

      setQuizFinished(true);
      handleRestartQuiz();
      setGameOver(false);
      setSelectedGameIndex("default");
    }
  }, [
    gameOver,
    allGameResults.length,
    correctAnswersCount,
    shuffledQuestions.length,
    incorrectResponses,
    correctResponses,
  ]);

  const handleStartQuizRandom = async (selected_quiz_type) => {
    console.log("Quiz started");
    setQuizType(selected_quiz_type);
    if (selected_quiz_type === "Warning Signs") {
      const shuffledRandomQuestions = shuffle(questions);
      setShuffledQuestions(shuffledRandomQuestions);
    }
    if (selected_quiz_type === "Warning Signs 20") {
      const shuffledRandomQuestions = shuffle(questions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 20));
    }
    if (selected_quiz_type === "Warning Signs 40") {
      const shuffledRandomQuestions = shuffle(questions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 40));
    }
    if (selected_quiz_type === "Roadworks Signs") {
      const shuffledRandomQuestions = shuffle(construction_questions);
      setShuffledQuestions(shuffledRandomQuestions);
    }
    if (selected_quiz_type === "Roadworks Signs 20") {
      const shuffledRandomQuestions = shuffle(construction_questions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 20));
    }
    if (selected_quiz_type === "Roadworks Signs 40") {
      const shuffledRandomQuestions = shuffle(construction_questions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 40));
    }
    if (selected_quiz_type === "Motorway Signs") {
      console.log("Before shuffle - " + motorwayQuestions.length);
      const shuffledRandomQuestions = shuffle(motorwayQuestions);
      console.log("After shuffle - " + shuffledRandomQuestions.length);
      setShuffledQuestions(shuffledRandomQuestions);
    }
    if (selected_quiz_type === "Regulatory Signs") {
      const shuffledRandomQuestions = shuffle(regulatoryQuestions);
      setShuffledQuestions(shuffledRandomQuestions);
    }
    if (selected_quiz_type === "Regulatory Signs 20") {
      const shuffledRandomQuestions = shuffle(regulatoryQuestions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 20));
    }
    if (selected_quiz_type === "Regulatory Signs 40") {
      const shuffledRandomQuestions = shuffle(regulatoryQuestions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 40));
    }
    if (selected_quiz_type === "Information Signs") {
      const shuffledRandomQuestions = shuffle(informationRoadsignQuestions);
      setShuffledQuestions(shuffledRandomQuestions);
    }
    if (selected_quiz_type === "Information Signs 20") {
      const shuffledRandomQuestions = shuffle(informationRoadsignQuestions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 20));
    }
    if (selected_quiz_type === "Random Signs") {
      const shuffledRandomQuestions = shuffle(allQuestions);
      setShuffledQuestions(shuffledRandomQuestions);
    }
    if (selected_quiz_type === "Random Signs 20") {
      const shuffledRandomQuestions = shuffle(allQuestions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 20));
    }
    if (selected_quiz_type === "Random Signs 40") {
      const shuffledRandomQuestions = shuffle(allQuestions);
      setShuffledQuestions(shuffledRandomQuestions.slice(0, 40));
    }

    setCurrentQuestionIndex(0);
    setCorrectAnswersCount(0);
    setIncorrectResponses([]);
    setCorrectResponses([]);
    setQuizStarted(true);
    await delay(200);
    handleClickScroll();
  };

  //This is a delay that is called before the scroll, just to ensure the component has fully rendered.
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  // Will scroll smoothly to the top of the next section
  const handleClickScroll = () => {
    const element = document.getElementById("section-1");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  //Scrolls the user back to the top of the screen.
  const scrollBackToTop = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  // Display game results when the user clicks the button
  const handleViewResults = (index) => {
    console.log(allGameResults);
    console.log(currGameResults);
    const selectedIndex = index;
    console.log(selectedIndex);
    setSelectedGameIndex(selectedIndex);
  };

  // Display game results when the user clicks the button, show all answers, only incorrect, or only correct
  const filterTableOptions = (option) => {
    const selectedOption = option;
    setViewOption(selectedOption);
  };

  const showFeedbackForm = async () => {
    setFeedbackFormState(true);
    await delay(200);
    scrollBackToTop("section-4");

    /*if (feedbackFormState) {
      setFeedbackFormState(false);
    } else {
      setFeedbackFormState(true);
      await delay(200);
      scrollBackToTop("section-3");
    }*/
  };

  const showDisclaimer = async () => {
    setDisclaimerState(true);
    await delay(200);
    scrollBackToTop("section-4");
  };

  // Closing the modal component.
  const handleClose = () => {
    console.log("handleClose");
    setQuizFinished(false);
  };

  const currentQuestionData = shuffledQuestions[currentQuestionIndex];
  return (
    <div className="app">
      <div className="container">
        <Toaster position="top-right" />
        <Analytics />
        {/* 

        Rendering the Navbar component to the main app.js screen.
        
        */}
        <NavBar
          onCategoryClick={handleStartQuizRandom}
          showFeedbackForm={showFeedbackForm}
        />
        <div style={{ textAlignVertical: "center", textAlign: "center" }}>
          <h2 id="section-2" className="display-5 mt-3">
            Road Signs Ireland
          </h2>
          <p id="divElement" className="lead">
            Welcome to Road Signs Ireland - Your Ultimate Irish Road Signs
            Practice Platform! <br />
            <br />
            Preparing for your Irish Driving Test? Mastering road signs is a
            crucial step in ensuring a safe and successful driving experience.
            Road Signs Ireland is here to make your journey to becoming a
            confident and responsible driver easier than ever. <br />
            <br />
            Our user-friendly website is dedicated to helping you learn and
            practice the road signs essential for the Irish Driving Test.
            Whether you're a novice driver or seeking to refresh your knowledge,
            Road Signs Ireland has the resources and tools you need to succeed.
          </p>
        </div>
        {/* 

        Displaying the 6 different quiz options to the users.
        
        */}
        <div
          className="row bg-light border rounded"
          style={{
            padding: "20px",
            textAlignVertical: "center",
            textAlign: "center",
          }}
        >
          <h2 className="display-6">
            Select a set of practice questions to begin.
          </h2>
          <div className="row row-cols-1 row-cols-md-3 g-3" align="center">
            <div className="col">
              <div className="card">
                <center>
                  <img
                    className="card-img-top mt-2"
                    src={roundaboutAhead}
                    alt="Warning Card"
                    style={{ width: 140, height: 140 }}
                  ></img>
                </center>
                <div className="card-body">
                  <h5 className="card-title">Warning Road Signs</h5>
                  <p className="card-text">
                    A collection of the warning road signs
                  </p>
                  <button
                    className="btn btn-outline-secondary dropdown-toggle mt-3"
                    id="warningQuestionsButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Warning Road Signs
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="warningQuestionsButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Warning Signs 20")
                        }
                      >
                        {" "}
                        20 Warning Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Warning Signs 40")
                        }
                      >
                        {" "}
                        40 Warning Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleStartQuizRandom("Warning Signs")}
                      >
                        {" "}
                        All Warning Signs
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <center>
                  <img
                    className="card-img-top mt-2"
                    src={roadworksahead}
                    alt="Roadwork Card"
                    style={{ width: 140, height: 140 }}
                  ></img>
                </center>
                <div className="card-body">
                  <h5 className="card-title">Roadworks Road Signs</h5>
                  <p className="card-text">
                    A collection of the road works signs
                  </p>
                  <button
                    className="btn btn-outline-secondary dropdown-toggle mt-3"
                    id="roadworksQuestionsButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Roadwork Signs
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="roadworksQuestionsButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Roadworks Signs 20")
                        }
                      >
                        {" "}
                        20 Road Work Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Roadworks Signs 40")
                        }
                      >
                        {" "}
                        40 Road Work Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleStartQuizRandom("Roadworks Signs")}
                      >
                        {" "}
                        All Road Work Signs
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card text-center">
                <center>
                  <img
                    className="card-img-top mt-2"
                    src={motorwaysign}
                    alt=" Motorway Card"
                    style={{ width: 140, height: 140 }}
                  ></img>
                </center>
                <div className="card-body">
                  <h5 className="card-title">Motorway Road Signs</h5>
                  <p className="card-text">
                    A collection of the motorway road signs
                  </p>
                  <button
                    className="btn btn-outline-secondary mt-3"
                    onClick={() => handleStartQuizRandom("Motorway Signs")}
                  >
                    Motorway Signs
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card text-center">
                <center>
                  <img
                    className="card-img-top mt-2"
                    src={no_straight_ahead}
                    alt="Regulatory Card"
                    style={{ width: 140, height: 140 }}
                  ></img>
                </center>
                <div className="card-body">
                  <h5 className="card-title">Regulatory Road Signs</h5>
                  <p className="card-text">
                    A collection of the regulatory road signs
                  </p>
                  <button
                    className="btn btn-outline-secondary dropdown-toggle mt-3"
                    id="regulatoryQuestionsButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Regulatory Signs
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="regulatoryQuestionsButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Regulatory Signs 20")
                        }
                      >
                        {" "}
                        20 Regulatory Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Regulatory Signs 40")
                        }
                      >
                        {" "}
                        40 Regulatory Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Regulatory Signs")
                        }
                      >
                        {" "}
                        All Regulatory Signs
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card text-center">
                <center>
                  <img
                    className="card-img-top mt-2"
                    src={hospital_ahead}
                    alt="Information Card"
                    style={{ width: 140, height: 140 }}
                  ></img>
                </center>
                <div className="card-body">
                  <h5 className="card-title">Information Road Signs</h5>
                  <p className="card-text">
                    A collection of the information road signs
                  </p>
                  <button
                    className="btn btn-outline-secondary dropdown-toggle mt-3"
                    type="button"
                    id="informationQuestionsButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Information Signs
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="informationQuestionsButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Information Signs 20")
                        }
                      >
                        {" "}
                        20 Information Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleStartQuizRandom("Information Signs")
                        }
                      >
                        {" "}
                        All Information Signs
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card text-center">
                <center>
                  <img
                    className="card-img-top mt-2"
                    src={signing_to_approved_tourist_information}
                    alt="Random Card"
                    style={{ width: 140, height: 140 }}
                  ></img>
                </center>
                <div className="card-body">
                  <h5 className="card-title">Random Questions</h5>
                  <p className="card-text">
                    A collection of the all road signs shuffled
                  </p>
                  <button
                    className="btn btn-outline-secondary dropdown-toggle mt-3"
                    type="button"
                    id="randomQuestionsButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Random Signs
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="randomQuestionsButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleStartQuizRandom("Random Signs 20")}
                      >
                        {" "}
                        20 Random Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleStartQuizRandom("Random Signs 40")}
                      >
                        {" "}
                        40 Random Signs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleStartQuizRandom("Random Signs")}
                      >
                        {" "}
                        All Signs
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 

        When the quiz is started and the questions have been shuffled, we can render the Question component to the 
        screen so the user can start interacting with the quiz. 
        
        */}
        {quizStarted && shuffledQuestions.length > 0 && (
          <div
            id="section-1"
            className="quiz-content"
            style={{ padding: "10px" }}
          >
            <Question
              question={currentQuestionData.roadSignOption}
              answers={currentQuestionData?.answerOptions?.map(
                (option) => option.answerText
              )}
              onSelectAnswer={handleSelectAnswer}
              currentQuestionIndex={currentQuestionIndex}
              totalQuestions={shuffledQuestions.length}
              finalScore={correctAnswersCount}
              restartQuiz={handleRestartQuiz}
              quizType={quizType}
            />
          </div>
        )}
        {/* 

        Displaying the Modal (popup) with the quiz results when the user is finished the quiz 
        
        */}

        {quizFinished && (
          <DisplayModalWithResults
            handleQuizState={handleClose}
            state={quizFinished}
            currQuiz={currGameResults}
            quiz_name={quizType}
          />
        )}

        {allGameResults.length > 0 && (
          //currentQuestionIndex === shuffledQuestions.length - 1 && (
          <div
            className="row bg-light border rounded mt-3"
            style={{ padding: "10px" }}
          >
            {/* Display individual game results in a dropdown list for the user to choose from*/}
            <div>
              <h2 className="display-6">All Game Results</h2>
              <DisplayResults
                gameResults={allGameResults}
                viewResults={handleViewResults}
                selectedFilter={filterTableOptions}
              />
              {/* Display the default game (Users first Game) to the screen for the user to see.*/}
              {selectedGameIndex === "default" && (
                <ResultTable
                  allGameResults={allGameResults}
                  selectedGameIndex={0}
                  setViewOption={viewOption}
                />
              )}
              {/* Display the choosen game to the screen for the user to see.*/}
              {selectedGameIndex !== "default" && (
                <ResultTable
                  allGameResults={allGameResults}
                  selectedGameIndex={selectedGameIndex}
                  setViewOption={viewOption}
                />
              )}
            </div>
          </div>
        )}
        <div id="section-4">
          {feedbackFormState && <ContactUs hideForm={setFeedbackFormState} />}
          {disclaimerState && (
            <Disclaimer hideDisclaimer={setDisclaimerState} />
          )}
        </div>

        <footer className="bg-secondary py-5 mt-5">
          <div id="section-3" className="container text-light text-center">
            <p className="display-5 mb-3">Road Signs Ireland</p>
            <small className="text-light-50 text-center">
              &copy; Copyright by Road Signs Ireland. All rights reserved.
            </small>

            <button
              type="button"
              className="btn btn-outline-light btn-sm ms-2"
              onClick={() => scrollBackToTop("section-2")}
            >
              Back to top
            </button>
            <button
              type="button"
              className="btn btn-outline-light btn-sm ms-2"
              onClick={() => showFeedbackForm()}
            >
              Contact Us
            </button>
            <button
              type="button"
              className="btn btn-outline-light btn-sm ms-2"
              onClick={() => showDisclaimer()}
            >
              Disclaimer
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
