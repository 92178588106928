import React from "react";

const display_results = ({ gameResults, viewResults, selectedFilter }) => {
  return (
    <div>
      <button
        className="btn btn-secondary dropdown-toggle mb-2"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Select Game
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {gameResults.map((game, index) => (
          <li key={index}>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => viewResults(index)}
            >
              Game {game.gameIndex}
            </button>
          </li>
        ))}
      </ul>

      <button
        className="btn btn-secondary dropdown-toggle mb-2 ms-2"
        type="button"
        id="filterResultsButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Filter Results
      </button>
      <ul className="dropdown-menu" aria-labelledby="filterResultsButton">
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => selectedFilter(1)}
          >
            {" "}
            All answers
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => selectedFilter(2)}
          >
            {" "}
            Incorrect answers
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => selectedFilter(3)}
          >
            {" "}
            Correct answers
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => selectedFilter(null)}
          >
            {" "}
            Hide answers
          </button>
        </li>
      </ul>
    </div>
  );
};

export default display_results;
