export const Disclaimer = ({ hideDisclaimer }) => {
  return (
    <div
      className="container bg-light border rounded mt-4 col-8 position-relative"
      style={{
        padding: "20px",
      }}
    >
      <h2 id="section-2" className="display-6">
        Disclaimer
      </h2>
      <p id="divElement" className="lead fs-6">
        Road Signs Ireland is a platform designed to assist users in practicing
        road signs relevant to the Irish Driving Test. While we strive to
        provide accurate and up-to-date information, please note that Road Signs
        Ireland is not affiliated with any official driving test authorities.{" "}
        <br />
        <br />
        The content provided on Road Signs Ireland is for educational purposes
        only and should not be considered as official driving test information.
        Users are encouraged to consult official driving test resources and
        materials provided by relevant authorities for the most accurate and
        comprehensive information regarding the Irish Driving Test. <br />
        <br />
        Road Signs Ireland does not guarantee the accuracy, completeness, or
        reliability of the information provided on this platform. Users are
        responsible for verifying the information and using it at their own
        discretion. <br />
        <br />
        By using Road Signs Ireland, you agree that we shall not be held liable
        for any errors, inaccuracies, or omissions in the content provided. We
        strongly advise users to supplement their learning with additional
        resources and seek professional guidance if needed.
        <br />
        <br />
        Thank you for choosing Road Signs Ireland as your road signs practice
        platform. Drive safely!
      </p>
      <br />
      <button
        className="btn btn-outline-secondary position-absolute bottom-0 end-0 mb-3 me-3"
        style={{}}
        onClick={() => hideDisclaimer(false)}
      >
        Hide
      </button>
    </div>
  );
};
