import React from "react";

const results_table = ({
  selectedGameIndex,
  allGameResults,
  setViewOption,
}) => {
  return (
    <div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          Game {Number(selectedGameIndex) + 1}
        </li>
        <li className="list-group-item">
          Correct Answers: {allGameResults[selectedGameIndex].correctAnswers}
        </li>
        <li className="list-group-item">
          Total Questions: {allGameResults[selectedGameIndex].totalQuestions}
        </li>
        <li className="list-group-item">
          Incorrect Responses:{" "}
          {allGameResults[selectedGameIndex].incorrectResponses.length}
        </li>
      </ul>
      <table className="table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Your Answer</th>
            <th>Correct Answer</th>
          </tr>
        </thead>
        {(setViewOption === 2 || setViewOption === 1) && (
          <tbody>
            {allGameResults[selectedGameIndex].incorrectResponses.map(
              (response, index) => (
                <tr key={index}>
                  <td>
                    <img
                      className="rounded mx-auto d-block mt-2 mb-2"
                      style={{ width: 120, height: 120 }}
                      src={response.question}
                      alt="Question"
                    ></img>
                  </td>
                  <td className="table-danger">{response.selectedAnswer}</td>
                  <td style={{ textAlign: "center" }} className="table-success">
                    {response.correctAnswer}
                  </td>
                </tr>
              )
            )}
          </tbody>
        )}
        {(setViewOption === 3 || setViewOption === 1) && (
          <tbody>
            {allGameResults[selectedGameIndex].correctResponses.map(
              (response, index) => (
                <tr key={index}>
                  <td>
                    <img
                      className="rounded mx-auto d-block mt-2 mb-2"
                      style={{ width: 120, height: 120 }}
                      src={response.question}
                      alt="Question"
                    ></img>
                  </td>
                  <td className="table-success">{response.selectedAnswer}</td>
                  <td style={{ textAlign: "center" }} className="table-success">
                    {response.correctAnswer}
                  </td>
                </tr>
              )
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default results_table;
