import roundaboutAhead from "./roadsigns/warning_road_signs/roundabout_ahead.png";
import { motion } from "framer-motion";

function NavBar({ onCategoryClick, showFeedbackForm }) {
  return (
    <motion.nav
      className="navbar navbar-expand-md navbar-dark bg-secondary"
      style={{ backgroundColor: "#ADBC9F" }}
      initial={{ y: 100, x: 0, opacity: 0 }}
      animate={{ y: 0, x: 0, opacity: 1 }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="http://roadsignsireland.ie">
          <img
            src={roundaboutAhead}
            alt="Logo"
            width="28"
            height="28"
            className="d-inline-block align-text-top me-2"
          />
          Road Signs Ireland
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainNavbar"
          aria-controls="mainNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="mainNavbar">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="http://roadsignsireland.ie"
              >
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="quizOptions"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Categories
              </a>
              <ul className="dropdown-menu" aria-labelledby="quizOptions">
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => onCategoryClick("Warning Signs")}
                  >
                    Warning Signs
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => onCategoryClick("Roadworks Signs")}
                  >
                    Road Works Signs
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => onCategoryClick("Motorway Signs")}
                  >
                    Motorway Signs
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => onCategoryClick("Regulatory Signs")}
                  >
                    Regulatory Signs
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => onCategoryClick("Information Signs")}
                  >
                    Information Road Signs
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => onCategoryClick("Random Signs")}
                  >
                    Random Road Signs
                  </button>
                </li>
              </ul>
            </li>
          </ul>
          <button
            type="button"
            className="btn btn-outline-light btn-sm"
            onClick={() => showFeedbackForm(true)}
          >
            Contact Us
          </button>
        </div>
      </div>
    </motion.nav>
  );
}

export default NavBar;
