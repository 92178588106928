import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { toast } from "react-hot-toast";

export const ContactUs = ({ hideForm }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          toast.success("Email sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error);
          toast.error(error);
        }
      );
  };

  return (
    <div
      className="container bg-light border rounded mt-4 col-8 position-relative"
      style={{
        padding: "20px",
      }}
    >
      <form name="contact" ref={form} onSubmit={sendEmail}>
        <input type="hidden" name="form-name" value="contact"></input>
        <h1 className="display-6" style={{ textAlign: "center" }}>
          Contact Us
        </h1>
        <h5 className="text-muted" style={{ textAlign: "center" }}>
          The best way to contact us is to use our contact form
        </h5>
        <div className="input mb-3">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className="form-control"
            name="user_email"
            placeholder="name@example.ie"
            required
          />
        </div>
        <div className="input mb-3">
          <label htmlFor="message">Message</label>
          <textarea
            className="form-control"
            name="message"
            rows="3"
            required
          ></textarea>
        </div>
        <button type="submit" value={"Send"} className="btn btn-secondary">
          Submit
        </button>
      </form>
      <button
        className="btn btn-outline-secondary position-absolute bottom-0 end-0 mb-3 me-3"
        style={{}}
        onClick={() => hideForm(false)}
      >
        Hide
      </button>
    </div>
  );
};
