import React from "react";

const Question = ({
  question,
  answers,
  onSelectAnswer,
  currentQuestionIndex,
  totalQuestions,
  restartQuiz,
  quizType,
}) => {
  var quizTypeNoNums = quizType.replace(/[0-9]/g, "");

  return (
    <div className="question-container">
      <h1 className="display-6">{quizTypeNoNums}</h1>
      <div className="col">
        <h3>
          <span className="badge bg-secondary">
            Question {currentQuestionIndex + 1} /{totalQuestions}
          </span>
        </h3>
      </div>
      <div className="row mt-3 gx-5">
        <div className="col bg-light border rounded">
          <img
            className="rounded mx-auto d-block mt-2 mb-2"
            style={{ width: 240, height: 240 }}
            alt="Question"
            src={question}
          ></img>
        </div>
        <div className="col">
          <div className="row">
            {answers.map((answer, index) => (
              <button
                className="btn btn-outline-secondary mt-1"
                key={index}
                onClick={() => onSelectAnswer(index)}
              >
                {answer}
              </button>
            ))}
          </div>
          <div className="row"></div>
          <div className="row">
            <button
              className="btn btn-secondary mt-5"
              onClick={() => restartQuiz()}
            >
              Restart Game
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;

/*
<div className="row bg-light border rounded mt-3">
        <h1>Road Signs Ireland Quiz</h1>
        <h4>Select a set of practice questions to begin.</h4>
        <p>
          ttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt
          tttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt
          tttttttttttttttttttttttttttttttttt
        </p>
        <div className="col">
          <div className="card" style={{ width: "14rem" }}>
            <img
              className="card-img-top"
              src={roundaboutAhead}
              alt="Carc Image cap"
            ></img>
            <div className="card-body">
              <h5 className="card-title">Random Questions</h5>
              <p className="card-text">Practice All Signs</p>
              <button
                className="btn btn-outline-dark mt-3 mr-3"
                onClick={handleRandomClick}
              >
                Random Questions
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card" style={{ width: "14rem" }}>
            <img
              className="card-img-top"
              src={roadworksahead}
              alt="Carc Image cap"
            ></img>
            <div className="card-body">
              <h5 className="card-title">Road Work Signs</h5>
              <p className="card-text">Practice Roadworks signs.</p>
              <button
                className="btn btn-outline-dark mt-3 mr-3"
                onClick={handleRandomClick}
              >
                Roadwork Signs
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card" style={{ width: "14rem" }}>
            <img
              className="card-img-top"
              src={motorwaysign}
              alt="Carc Image cap"
            ></img>
            <div className="card-body">
              <h5 className="card-title">Motorway Questions</h5>
              <p className="card-text">Practice Motorway signs.</p>
              <button
                className="btn btn-outline-dark mt-3 mr-3"
                onClick={handleRandomClick}
              >
                Motorway Signs
              </button>
            </div>
          </div>
        </div>
      </div>*/
