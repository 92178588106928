//65 Yellow Questions
const yellowQuestions = [
  {
    roadSignOption: require("./roadsigns/warning_road_signs/accompanied_horses_and_ponies.png"),
    answerOptions: [
      { answerText: "Cattle and farm animals", isCorrect: false },
      { answerText: "Accompanied horses and ponies", isCorrect: true },
      { answerText: "farm animals crossing", isCorrect: false },
      { answerText: "Sheep crossing", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dangerous_corner_ahead_left.png"),
    answerOptions: [
      { answerText: "Dangerous corner ahead", isCorrect: true },
      { answerText: "Turn left ahead", isCorrect: false },
      { answerText: "Dangerour bend ahead", isCorrect: false },
      { answerText: "Turn left", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/roundabout_ahead.png"),
    answerOptions: [
      { answerText: "Loop road", isCorrect: false },
      { answerText: "Roundabout ahead", isCorrect: true },
      { answerText: "Mini roundabout ahead", isCorrect: false },
      { answerText: "Series of Dangerous bends ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/cattle_and_farm_animals.png"),
    answerOptions: [
      { answerText: "Cattle and farm animals", isCorrect: true },
      { answerText: "Accompanied horses and ponies", isCorrect: false },
      { answerText: "Animals crossing", isCorrect: false },
      { answerText: "Sheep crossing", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/crossroads.png"),
    answerOptions: [
      { answerText: "Crossroads with dual carriage way", isCorrect: false },
      { answerText: "T junction", isCorrect: false },
      { answerText: "Merging traffic", isCorrect: false },
      { answerText: "Crossroads", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/crossroads_ahead_dual_cway.png"),
    answerOptions: [
      { answerText: "Crossroads with dual carriage way", isCorrect: true },
      { answerText: "T junction", isCorrect: false },
      { answerText: "Merging traffic", isCorrect: false },
      { answerText: "Crossroads", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/crosswinds.png"),
    answerOptions: [
      { answerText: "Low flying aircraft", isCorrect: false },
      { answerText: "Crosswinds", isCorrect: true },
      { answerText: "Level crossing ahead unguarded", isCorrect: false },
      { answerText: "Tramlane crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/cyclists.png"),
    answerOptions: [
      { answerText: "Pedestrians and bicycles Only", isCorrect: false },
      { answerText: "Motor cyclists", isCorrect: false },
      { answerText: "Cyclists", isCorrect: true },
      { answerText: "Slippery for cyclists", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dangerous_bend_ahead.png"),
    answerOptions: [
      { answerText: "Dangerous corner ahead", isCorrect: false },
      { answerText: "Turn left", isCorrect: false },
      { answerText: "Keep left", isCorrect: false },
      { answerText: "Dangerous bend ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dangerous_corner_ahead_left.png"),
    answerOptions: [
      { answerText: "Turn left", isCorrect: false },
      { answerText: "Dangerous corner ahead", isCorrect: true },
      { answerText: "Dangerous bend ahead", isCorrect: false },
      { answerText: "Series of dangerous corners ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/deer_or_wild_animals.png"),
    answerOptions: [
      { answerText: "Deer or wild animals", isCorrect: true },
      { answerText: "Sheep", isCorrect: false },
      { answerText: "Cattle and farm animals", isCorrect: false },
      { answerText: "Accompanied horses and ponies", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dual_carriageway_ends.png"),
    answerOptions: [
      { answerText: "End of central reserve or obstruction", isCorrect: false },
      { answerText: "Obstruction between lanes", isCorrect: false },
      { answerText: "Dual carriage way ends", isCorrect: true },
      { answerText: "Road narrows on Both Sides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/lane_loss.png"),
    answerOptions: [
      { answerText: "Outside lane of Two Closed", isCorrect: false },
      { answerText: "Move to left", isCorrect: false },
      { answerText: "Road narrows from right", isCorrect: false },
      { answerText: "Lane Loss", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/level_crossing_ahead_guarded_by_gates_or_barrier.png"),
    answerOptions: [
      {
        answerText:
          "Level crossing ahead, guarded by gates or lifting barriers",
        isCorrect: true,
      },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barriers",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      { answerText: "Tram lane crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/level_crossing_ahead_unguarded_by_gates_or_barrier.png"),
    answerOptions: [
      {
        answerText:
          "Level crossing ahead, guarded by gates or lifting barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barriers",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/chevron_board_left_sharp_change_of_direction.png"),
    answerOptions: [
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: false,
      },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: true,
      },
      {
        answerText: "Barrier board",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/chevron_board_right_sharp_change_of_direction.png"),
    answerOptions: [
      {
        answerText: "Barrier board",
        isCorrect: false,
      },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the right)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/level_crossing_ahead_with_lights_barriers.png"),
    answerOptions: [
      {
        answerText:
          "Level crossing ahead, guarded by gates or lifting barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: true,
      },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barriers",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/loop_road_ahead.png"),
    answerOptions: [
      { answerText: "Dangerous bend ahead", isCorrect: false },
      { answerText: "Loop road ahead", isCorrect: true },
      { answerText: "U - turn ahead", isCorrect: false },
      { answerText: "Roundabout ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/low_flying_aircraft.png"),
    answerOptions: [
      { answerText: "Crosswinds", isCorrect: false },
      { answerText: "Direction sign", isCorrect: false },
      { answerText: "Low flying aircraft", isCorrect: true },
      { answerText: "Alternative Route Heavy Vehicles", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/merging_diverging_traffic.png"),
    answerOptions: [
      { answerText: "Merging traffic", isCorrect: false },
      { answerText: "Crossroads ahead", isCorrect: false },
      { answerText: "Y-junction", isCorrect: false },
      { answerText: "Merging/diverging traffic", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/merging_traffic.png"),
    answerOptions: [
      { answerText: "Merging traffic", isCorrect: true },
      { answerText: "Crossroads ahead", isCorrect: false },
      { answerText: "Y-junction", isCorrect: false },
      { answerText: "Merging/diverging traffic", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/mini_roundabout_ahead.png"),
    answerOptions: [
      { answerText: "Roundabout ahead", isCorrect: false },
      { answerText: "Loop road ahead", isCorrect: false },
      { answerText: "Mini roundabout ahead", isCorrect: true },
      { answerText: "Dangerous bend ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/overhead_electric_cables.png"),
    answerOptions: [
      { answerText: "Crosswinds", isCorrect: false },
      { answerText: "Overhead electric cables", isCorrect: true },
      { answerText: "Tram lane crossing ahead", isCorrect: false },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/drive-on-left_sign.png"),
    answerOptions: [
      {
        answerText: "Drive on left",
        isCorrect: true,
      },
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "No overtaking",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/Pedestrian_crossing_ahead.png"),
    answerOptions: [
      { answerText: "School ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      { answerText: "Pedestrian crossing ahead", isCorrect: true },
      { answerText: "School Children crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_divides.png"),
    answerOptions: [
      { answerText: "Road divides", isCorrect: true },
      { answerText: "Obstruction between lanes", isCorrect: false },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
      { answerText: "Two Way traffic", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_narrows_from_left_yel.png"),
    answerOptions: [
      { answerText: "Road narrows from left", isCorrect: true },
      { answerText: "Road narrows on both sides", isCorrect: false },
      { answerText: "Road narrows from right", isCorrect: false },
      { answerText: "Road divides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_narrows_from_right_yel.png"),
    answerOptions: [
      { answerText: "Road narrows from left", isCorrect: false },
      { answerText: "Road narrows on both sides", isCorrect: false },
      { answerText: "Road narrows from right", isCorrect: true },
      { answerText: "Road divides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_narrows_on_both_sides_yel.png"),
    answerOptions: [
      { answerText: "Road narrows from left", isCorrect: false },
      { answerText: "Road narrows on both sides", isCorrect: true },
      { answerText: "Road narrows from right", isCorrect: false },
      { answerText: "Road divides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/school_ahead.png"),
    answerOptions: [
      { answerText: "Pedestrian crossing ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      { answerText: "School ahead", isCorrect: true },
      { answerText: "School children crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/caution_children_crossing_residential.png"),
    answerOptions: [
      { answerText: "Pedestrian crossing ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: true,
      },
      { answerText: "School ahead", isCorrect: false },
      { answerText: "School children crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/school_children_crossing_ahead.png"),
    answerOptions: [
      { answerText: "Pedestrian crossing ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      { answerText: "School ahead", isCorrect: false },
      { answerText: "School children crossing ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/series_of_bumps_or_hollows_ahead.png"),
    answerOptions: [
      { answerText: "Sharp dip ahead", isCorrect: false },
      {
        answerText: "Series of bumps or hollows ahead",
        isCorrect: true,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: false },
      { answerText: "Uneven surface", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/series_of_dangerous_bends_ahead.png"),
    answerOptions: [
      { answerText: "Series of dangerous corners ahead", isCorrect: false },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      { answerText: "Dangerous corner ahead", isCorrect: false },
      { answerText: "Series of dangerous bends ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/series_of_dangerous_corners_ahead.png"),
    answerOptions: [
      { answerText: "Series of dangerous corners ahead", isCorrect: true },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      { answerText: "Dangerous corner ahead", isCorrect: false },
      { answerText: "Series of dangerous bends ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sharp_dip_ahead.png"),
    answerOptions: [
      { answerText: "Series of bumps or hollows ahead", isCorrect: false },
      {
        answerText: "Sharp dip ahead",
        isCorrect: true,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: false },
      { answerText: "Unever surface", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sharp_rise_ahead_eg_humpback-bridge.png"),
    answerOptions: [
      { answerText: "Sharp dip ahead", isCorrect: false },
      {
        answerText: "Unever surface",
        isCorrect: false,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: true },
      { answerText: "Series of bumps or hollows ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sheep.png"),
    answerOptions: [
      { answerText: "Cattle and farm animals", isCorrect: false },
      {
        answerText: "Sheep",
        isCorrect: true,
      },
      { answerText: "Deer or wild animals", isCorrect: false },
      { answerText: "accompanied horses or ponies", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sideroad.png"),
    answerOptions: [
      { answerText: "Crossroads", isCorrect: false },
      {
        answerText: "Merging traffic",
        isCorrect: false,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Sideroad", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/slippery_for_cyclists.png"),
    answerOptions: [
      { answerText: "Slippery for cyclists", isCorrect: true },
      {
        answerText: "Uneven surface",
        isCorrect: false,
      },
      { answerText: "Slippery road ahead", isCorrect: false },
      { answerText: "Cyclists", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/slippery_road_ahead.png"),
    answerOptions: [
      { answerText: "Uneven surface", isCorrect: false },
      {
        answerText: "Slippery for cyclists",
        isCorrect: false,
      },
      { answerText: "Slippery road ahead", isCorrect: true },
      { answerText: "Uneven surface", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/slow_automatic_level_crossing.png"),
    answerOptions: [
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText: "Automatic level crossing ahead",
        isCorrect: true,
      },
      { answerText: "Stop when lights are red", isCorrect: false },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barrier",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/staggered_crossroads.png"),
    answerOptions: [
      { answerText: "Crossroads", isCorrect: false },
      {
        answerText: "Side road",
        isCorrect: false,
      },
      { answerText: "Staggered crossroads", isCorrect: true },
      { answerText: "T-junction", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/start_of_climbing_lane.png"),
    answerOptions: [
      { answerText: "Lane loss", isCorrect: false },
      {
        answerText: "Start of passing lane",
        isCorrect: false,
      },
      { answerText: "Two way traffic flow", isCorrect: false },
      { answerText: "Start of climbing lane", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/start_of_passing_lane.png"),
    answerOptions: [
      { answerText: "Start of climbing lane", isCorrect: false },
      {
        answerText: "Diverging traffic",
        isCorrect: false,
      },
      { answerText: "Start of passing lane", isCorrect: true },
      { answerText: "Dual carriage way ends", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/steep_ascent_ahead.png"),
    answerOptions: [
      { answerText: "Steep ascent ahead", isCorrect: true },
      {
        answerText: "Sharp rise ahead eg humpback bridge",
        isCorrect: false,
      },
      { answerText: "Steep descent ahead", isCorrect: false },
      { answerText: "Slippery road ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/steep_decent_ahead.png"),
    answerOptions: [
      { answerText: "Steep ascent ahead", isCorrect: false },
      {
        answerText: "Slippery road ahead",
        isCorrect: false,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: false },
      { answerText: "Steep descent ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/stop_when_red_lights_show.png"),
    answerOptions: [
      { answerText: "Stop", isCorrect: false },
      {
        answerText: "Yield",
        isCorrect: false,
      },
      { answerText: "Stop when red lights show", isCorrect: true },
      { answerText: "Traffic signals ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/T_junction_ahead.png"),
    answerOptions: [
      { answerText: "Crossroads", isCorrect: false },
      {
        answerText: "T-junction ahead",
        isCorrect: true,
      },
      { answerText: "T-junction with dual carriage way", isCorrect: false },
      { answerText: "Sideroad", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/traffic_crossover_ahead.jpeg"),
    answerOptions: [
      { answerText: "Traffic crossover ahead", isCorrect: true },
      {
        answerText: "T-junction ahead",
        isCorrect: false,
      },
      { answerText: "T-junction with dual carriage way", isCorrect: false },
      { answerText: "Sideroad", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/T_junction_ahead_dual_cway.png"),
    answerOptions: [
      { answerText: "T-junction with dual carriage way", isCorrect: true },
      {
        answerText: "T-junction ahead",
        isCorrect: false,
      },
      { answerText: "Crossroads with dual carriage way", isCorrect: false },
      { answerText: "Crossroads", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/traffic_signals_ahead.png"),
    answerOptions: [
      { answerText: "Stop", isCorrect: false },
      {
        answerText: "Stop when lights are red",
        isCorrect: false,
      },
      { answerText: "Traffic signals ahead", isCorrect: true },
      { answerText: "Yield", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tram_warning_look_both_ways.png"),
    answerOptions: [
      { answerText: "Tram lane crossing ahead", isCorrect: false },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      { answerText: "Automatic level crossing ahead", isCorrect: false },
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tram_warning_look_left.png"),
    answerOptions: [
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: true,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      { answerText: "Automatic level crossing ahead", isCorrect: false },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tram_warning_look_right.png"),
    answerOptions: [
      {
        answerText: "Automatic level crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: true,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tramlane_crossing_ahead.png"),
    answerOptions: [
      {
        answerText: "Automatic level crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: false,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/falling_rocks.jpg"),
    answerOptions: [
      {
        answerText: "Falling rocks",
        isCorrect: true,
      },
      {
        answerText: "Steep descent ahead",
        isCorrect: false,
      },
      {
        answerText: "Unprotected quay, canal or river",
        isCorrect: false,
      },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tunnel_ahead.png"),
    answerOptions: [
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Tunnel ahead",
        isCorrect: true,
      },
      {
        answerText: "No overtaking",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/two-way-traffic_yellow.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Two way traffic",
        isCorrect: true,
      },
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
      {
        answerText: "Straight ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/low_bridge_ahead.png"),
    answerOptions: [
      {
        answerText: "Steep descent ahead",
        isCorrect: false,
      },
      {
        answerText: "Low bridge ahead",
        isCorrect: true,
      },
      {
        answerText: "Unprotected quay, canal or river",
        isCorrect: false,
      },
      {
        answerText: "Sharp rise ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/unprotected_quay_canal_river.png"),
    answerOptions: [
      {
        answerText: "Steep descent ahead",
        isCorrect: false,
      },
      {
        answerText: "Crosswinds",
        isCorrect: false,
      },
      {
        answerText: "Unprotected quay, canal or river",
        isCorrect: true,
      },
      {
        answerText: "Sharp rise ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/y-junction.png"),
    answerOptions: [
      {
        answerText: "Y-junction",
        isCorrect: true,
      },
      {
        answerText: "Diverging traffic",
        isCorrect: false,
      },
      {
        answerText: "Merging traffic",
        isCorrect: false,
      },
      {
        answerText: "T-junction",
        isCorrect: false,
      },
    ],
  },
];

export default yellowQuestions;
