// We have 54 and there is 54 Construction Questions

const constructionQuestions = [
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_ahead_construction.png"),
    answerOptions: [
      {
        answerText: "Detour ahead",
        isCorrect: true,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Town or village sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_destination.png"),
    answerOptions: [
      {
        answerText: "Town or village sign",
        isCorrect: false,
      },
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Detour Destination",
        isCorrect: true,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_to_left_construction.jpg"),
    answerOptions: [
      {
        answerText: "Detour to left",
        isCorrect: true,
      },
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Detour Destination",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_to_right_construction.jpg"),
    answerOptions: [
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Detour to right",
        isCorrect: true,
      },
      {
        answerText: "Detour Destination",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_left_construction.png"),
    answerOptions: [
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic left",
        isCorrect: true,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_right_construction.png"),
    answerOptions: [
      {
        answerText: "Detour to right",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic left",
        isCorrect: false,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic right",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_alt_construction.png"),
    answerOptions: [
      {
        answerText: "Diverted traffic",
        isCorrect: true,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_alt1_construction.png"),
    answerOptions: [
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: true,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/lanes_rejoin_at_crossover.png"),
    answerOptions: [
      {
        answerText: "Lanes rejoin at crossover",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
      {
        answerText: "Lanes rejoin at crossover",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/lanes_diverge_at_crossover.png"),
    answerOptions: [
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: true,
      },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
      {
        answerText: "Lanes rejoin at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end_of_central_reserve_or_obstruction_construction.png"),
    answerOptions: [
      {
        answerText: "End of obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "End of central reserve or obstruction",
        isCorrect: true,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end_of_detour_construction.png"),
    answerOptions: [
      {
        answerText: "End",
        isCorrect: false,
      },
      {
        answerText: "Detour to right",
        isCorrect: false,
      },
      {
        answerText: "End of detour",
        isCorrect: true,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end_of_obstruction_between_lanes_construction.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "End of central reserve or obstruction",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "End of obstruction between lanes",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/flagman_ahead_construction.png"),
    answerOptions: [
      {
        answerText: "School ahead",
        isCorrect: false,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: true,
      },
      {
        answerText: "Roadworks ahead",
        isCorrect: false,
      },
      {
        answerText: "Pedestrians cross to right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/hump_or_ramp_construction.png"),
    answerOptions: [
      { answerText: "Sharp dip ahead", isCorrect: false },
      {
        answerText: "Unever surface",
        isCorrect: false,
      },
      { answerText: "Hump or ramp", isCorrect: true },
      { answerText: "Series of bumps or hollows ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_left_one_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: true,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Move to right (one lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_left_two_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Road narrows from right",
        isCorrect: false,
      },
      {
        answerText: "Two-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Move to left (two lane)",
        isCorrect: true,
      },
      {
        answerText: "Move to right (two lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_right_one_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Move to right (one lane)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_right_two_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
      {
        answerText: "Two-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Move to left (two lane)",
        isCorrect: false,
      },
      {
        answerText: "Move to right (two lane)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/nearside_lane_closed2.png"),
    answerOptions: [
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: true,
      },
      {
        answerText: "Two way traffic",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/nearside_lane_closed3.png"),
    answerOptions: [
      {
        answerText: "Outside lane (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Two way traffic",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: true,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/obstruction_between_lanes_construction.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: true,
      },
      {
        answerText: "End of central reserve or obstruction",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/offside_lane_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of four) closed",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of four) closed",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/nearside_lane_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of four) closed",
        isCorrect: true,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of four) closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/queues_likely.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Queues likely",
        isCorrect: true,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/loose_chippings.png"),
    answerOptions: [
      {
        answerText: "Loose chippings",
        isCorrect: true,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Uneven surface",
        isCorrect: false,
      },
      {
        answerText: "Hump or ramp",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/one-lane_crossover_back_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: true,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/one-lane_crossover_out_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: true,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/outside_lane_closed_2.png"),
    answerOptions: [
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: true,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/outside_lane_closed_3.png"),
    answerOptions: [
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of three) closed",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/overhead_electric_cables_construction.png"),
    answerOptions: [
      { answerText: "Crosswinds", isCorrect: false },
      { answerText: "Overhead Electric Cables", isCorrect: true },
      { answerText: "Tram Lane Crossing Ahead", isCorrect: false },
      {
        answerText: "Level Crossing Ahead with Lights and Barriers",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/pedestrian_cross_to_left_construction.png"),
    answerOptions: [
      {
        answerText: "Pedestrians cross to left",
        isCorrect: true,
      },
      {
        answerText: "Pedestrians cross to right",
        isCorrect: false,
      },
      {
        answerText: "Site access on left",
        isCorrect: false,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/pedestrian_cross_to_right_construction.png"),
    answerOptions: [
      {
        answerText: "Pedestrians cross to left",
        isCorrect: false,
      },
      {
        answerText: "Pedestrians cross to right",
        isCorrect: true,
      },
      {
        answerText: "Site access on right",
        isCorrect: false,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Road closed",
        isCorrect: true,
      },
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "Roadworks ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_narrows_on_both_sides.png"),
    answerOptions: [
      {
        answerText: "End of obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Road narrows from right",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "Road narrows on both sides",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_narrows_on_left.png"),
    answerOptions: [
      {
        answerText: "Road narrows on left",
        isCorrect: true,
      },
      {
        answerText: "Road narrows from right",
        isCorrect: false,
      },
      {
        answerText: "Move to right (one lane)",
        isCorrect: false,
      },
      {
        answerText: "Road narrows on both sides",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_narrows_on_right.png"),
    answerOptions: [
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
      {
        answerText: "Road narrows from right",
        isCorrect: true,
      },
      {
        answerText: "Move to rileft (one lane)",
        isCorrect: false,
      },
      {
        answerText: "Road narrows on both sides",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/distance.png"),
    answerOptions: [
      {
        answerText: "Distance",
        isCorrect: true,
      },
      {
        answerText: "Direction and distance",
        isCorrect: false,
      },
      {
        answerText: "Direction",
        isCorrect: false,
      },
      {
        answerText: "Destination and distance sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/direction.png"),
    answerOptions: [
      {
        answerText: "Direction and distance",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Direction",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/direction_and_distance.png"),
    answerOptions: [
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Direction and distance",
        isCorrect: true,
      },
      {
        answerText: "Direction",
        isCorrect: false,
      },
      {
        answerText: "Destination and distance sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end.png"),
    answerOptions: [
      {
        answerText: "End of detour",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "No U-turn",
        isCorrect: false,
      },
      {
        answerText: "End",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/consealed_entrance.png"),
    answerOptions: [
      {
        answerText: "Slow",
        isCorrect: false,
      },
      {
        answerText: "Sideroad",
        isCorrect: false,
      },
      {
        answerText: "Concealed entrance",
        isCorrect: true,
      },
      {
        answerText: "Cautionary speed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/use_hard_shoulder.png"),
    answerOptions: [
      {
        answerText: "Unfinished road surface",
        isCorrect: false,
      },
      {
        answerText: "Use hard shoulder",
        isCorrect: true,
      },
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
      {
        answerText: "Types of works",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/speed_limit_ahead.png"),
    answerOptions: [
      {
        answerText: "Speed limit ahead",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Slow",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/go.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/stop_circle.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: true,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "School wardens stop sign",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/go_irish.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go or Téigh",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/barrier_board.png"),
    answerOptions: [
      {
        answerText: "Barrier board",
        isCorrect: true,
      },
      {
        answerText: "Chevron board",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/chevron_board.png"),
    answerOptions: [
      {
        answerText: "Barrier board",
        isCorrect: false,
      },
      {
        answerText: "Chevron board",
        isCorrect: true,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the right)",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/hard_shoulder_closed.jpg"),
    answerOptions: [
      {
        answerText: "Use hard shoulder",
        isCorrect: false,
      },
      {
        answerText: "Unfinished road surface",
        isCorrect: false,
      },
      {
        answerText: "Types of works",
        isCorrect: true,
      },
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/unfinished_road_surface.jpg"),
    answerOptions: [
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
      {
        answerText: "Types of works",
        isCorrect: false,
      },
      {
        answerText: "Unfinished road surface",
        isCorrect: true,
      },
      {
        answerText: "Use hard shoulder",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/type_of_works.png"),
    answerOptions: [
      {
        answerText: "Unfinished road surface",
        isCorrect: false,
      },
      {
        answerText: "Concealed entrance",
        isCorrect: false,
      },
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
      {
        answerText: "Type of works",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/slow.png"),
    answerOptions: [
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "Cautionary speed",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Slow",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/cautionary_speed.png"),
    answerOptions: [
      {
        answerText: "Cautionary speed",
        isCorrect: true,
      },
      {
        answerText: "Speed limit ahead",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Distance",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/lenght.png"),
    answerOptions: [
      {
        answerText: "Length",
        isCorrect: true,
      },
      {
        answerText: "Distance",
        isCorrect: false,
      },
      {
        answerText: "Direction",
        isCorrect: false,
      },
      {
        answerText: "Speed limit ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/roadworks_ahead.png"),
    answerOptions: [
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
      {
        answerText: "Site access on right",
        isCorrect: false,
      },
      {
        answerText: "Roadworks ahead",
        isCorrect: true,
      },
      {
        answerText: "Pedestrian crossing to right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/side_road_left_construction.png"),
    answerOptions: [
      { answerText: "Site access on left", isCorrect: false },
      {
        answerText: "Side road on right",
        isCorrect: false,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on left", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/side_road_right_construction.png"),
    answerOptions: [
      { answerText: "Site access on right", isCorrect: false },
      {
        answerText: "Side road on right",
        isCorrect: true,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on left", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/single_lane_shuttle_working.png"),
    answerOptions: [
      {
        answerText: "Straight ahead",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
      {
        answerText: "Single lane (for shuttle working)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/site_access_on_left_construction.png"),
    answerOptions: [
      { answerText: "Site access on left", isCorrect: true },
      {
        answerText: "Site access on right",
        isCorrect: false,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on left", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/site_access_on_right_construction.png"),
    answerOptions: [
      { answerText: "Site access on left", isCorrect: true },
      {
        answerText: "Site access on right",
        isCorrect: true,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on right", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/slippery_road_ahead_construction.png"),
    answerOptions: [
      {
        answerText: "Uneven surface",
        isCorrect: false,
      },
      {
        answerText: "Loose chippings",
        isCorrect: false,
      },
      {
        answerText: "Slippery road",
        isCorrect: true,
      },
      {
        answerText: "Slippery for cyclists",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/start_of_central_reserve_or_obstruction_construction.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Start of central reserve or obstruction between lanes",
        isCorrect: true,
      },
      {
        answerText: "End of obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/temporary_traffic_signal_ahead.png"),
    answerOptions: [
      {
        answerText: "Traffic lights ahead",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
      {
        answerText: "Temporary traffic lights ahead",
        isCorrect: true,
      },
      {
        answerText: "Slow",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_lane_crossover_back_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (two lanes)",
        isCorrect: false,
      },
      {
        answerText: "Two-lanes crossover (back)",
        isCorrect: true,
      },
      {
        answerText: "Two-lanes crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_lane_crossover_out_construction.png"),
    answerOptions: [
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
      {
        answerText: "Two-lanes crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Two-lanes crossover (out)",
        isCorrect: true,
      },
      {
        answerText: "Move to right (two lanes)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_nearside_closed_3.png"),
    answerOptions: [
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Two offside lanes (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_nearside_lanes_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Two nearside lanes (of four) closed",
        isCorrect: true,
      },
      {
        answerText: "Two offside lanes (of four) closed",
        isCorrect: false,
      },
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_offside_lanes_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Two offside lanes (of four) closed",
        isCorrect: true,
      },
      {
        answerText: "Two nearside lanes (of four) closed",
        isCorrect: false,
      },
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_outside_closed_3.png"),
    answerOptions: [
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Two offside lanes (of three) closed",
        isCorrect: true,
      },
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two-way-traffic.png"),
    answerOptions: [
      {
        answerText: "Two way traffic",
        isCorrect: true,
      },
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Straight Ahead",
        isCorrect: false,
      },
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/uneven_surface_construction.png"),
    answerOptions: [
      {
        answerText: "Uneven surface",
        isCorrect: true,
      },
      {
        answerText: "Hump or ramp",
        isCorrect: false,
      },
      {
        answerText: "Slippery road",
        isCorrect: false,
      },
      {
        answerText: "Loose chippings",
        isCorrect: false,
      },
    ],
  },
];

export default constructionQuestions;
