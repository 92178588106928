// We have 21 and there is 21 Motorway Question Signs

const motorwayQuestions = [
  {
    roadSignOption: require("./roadsigns/motorway_signs/motorway_ahead.png"),
    answerOptions: [
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "Motorway ahead",
        isCorrect: true,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/motorway_ahead_english.png"),
    answerOptions: [
      {
        answerText: "Motorway ahead",
        isCorrect: true,
      },
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/route_confirmatory_sign_euro_plate.png"),
    answerOptions: [
      {
        answerText: "Motorway ahead",
        isCorrect: false,
      },
      {
        answerText: "Advance direction sign",
        isCorrect: false,
      },
      {
        answerText: "Route confirmatory sign with Euro Route marker plate.",
        isCorrect: true,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/entry_to_motorway.png"),
    answerOptions: [
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "Entry to motorway",
        isCorrect: true,
      },
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "100m to next exit",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/motorway_ends.png"),
    answerOptions: [
      {
        answerText: "End of motorway",
        isCorrect: true,
      },
      {
        answerText: "Motorway end 1km ahead",
        isCorrect: false,
      },
      {
        answerText: "Motorway end 500m ahead",
        isCorrect: false,
      },
      {
        answerText: "200m to next exit",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/motorway_ends_1km.png"),
    answerOptions: [
      {
        answerText: "Motorway end 500m ahead",
        isCorrect: false,
      },
      {
        answerText: "Entry to motorway",
        isCorrect: false,
      },
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "Motorway end 1km ahead",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/motorway_ends_500m.png"),
    answerOptions: [
      {
        answerText: "Entry to motorway",
        isCorrect: false,
      },
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "Motorway end 500m ahead",
        isCorrect: true,
      },
      {
        answerText: "Toll plaza ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/motorway_service_sign.png"),
    answerOptions: [
      {
        answerText: "200m to next exit",
        isCorrect: false,
      },
      {
        answerText: "Motorway service area information sign",
        isCorrect: true,
      },
      {
        answerText: "Motorway ends",
        isCorrect: false,
      },
      {
        answerText: "Lay-by ahead sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/garda_only.png"),
    answerOptions: [
      {
        answerText: "Authorised vehicles only",
        isCorrect: false,
      },
      {
        answerText: "Toll plaza ahead",
        isCorrect: false,
      },
      {
        answerText: "Garda only",
        isCorrect: true,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/lane_gain_motorway.png"),
    answerOptions: [
      {
        answerText: "300m to next exit",
        isCorrect: false,
      },
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "Advance direction sign",
        isCorrect: false,
      },
      {
        answerText: "Typical lane gain sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/route_confirmatory_sign_m7.png"),
    answerOptions: [
      {
        answerText: "Route confirmatory sign for M7",
        isCorrect: true,
      },
      {
        answerText: "Advance direction sign for destination",
        isCorrect: false,
      },
      {
        answerText: "2km next exit sign",
        isCorrect: false,
      },
      {
        answerText: "Route confirmatory sign with Euro Route marker plate",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/toll_charges.png"),
    answerOptions: [
      {
        answerText: "Toll plaza ahead",
        isCorrect: false,
      },
      {
        answerText: "Toll charges",
        isCorrect: true,
      },
      {
        answerText: "Motorway service area information sign",
        isCorrect: false,
      },
      {
        answerText: "Toll plaza information sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/toll_plaza_ahead.png"),
    answerOptions: [
      {
        answerText: "Motorway service area information sign",
        isCorrect: false,
      },
      {
        answerText: "Toll plaza information sign",
        isCorrect: false,
      },
      {
        answerText: "Toll plaza ahead",
        isCorrect: true,
      },
      {
        answerText: "Toll charges",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/toll_plaza_information_sign.png"),
    answerOptions: [
      {
        answerText: "Motorway service area information sign",
        isCorrect: false,
      },
      {
        answerText: "Toll plaza information sign",
        isCorrect: true,
      },
      {
        answerText: "Toll plaza ahead",
        isCorrect: false,
      },
      {
        answerText: "Toll charges",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/authorised_vehicles_only.png"),
    answerOptions: [
      {
        answerText: "Authorised vehicles only",
        isCorrect: true,
      },
      {
        answerText: "Garda only",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Toll plaza ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/advanced_direction_for_destination.png"),
    answerOptions: [
      {
        answerText: "2km next exit sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction sign for destination",
        isCorrect: true,
      },
      {
        answerText: "Route confirmatory sign for M7",
        isCorrect: false,
      },
      {
        answerText: "Route confirmatory sign with Euro Route marker plate",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/2km_next_exit_sign.png"),
    answerOptions: [
      {
        answerText: "Route confirmatory sign with Euro Route marker plate",
        isCorrect: false,
      },
      {
        answerText: "Advance direction sign for destination",
        isCorrect: false,
      },
      {
        answerText: "Route confirmatory sign for M7",
        isCorrect: false,
      },
      {
        answerText: "2km next exit sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/300m_to_next_exit.png"),
    answerOptions: [
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "100m to next exit",
        isCorrect: false,
      },
      {
        answerText: "200m to next exit",
        isCorrect: false,
      },
      {
        answerText: "300m to next exit",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/100m_to_next_exit.png"),
    answerOptions: [
      {
        answerText: "200m to next exit",
        isCorrect: false,
      },
      {
        answerText: "300m to next exit",
        isCorrect: false,
      },
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "100m to next exit",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/200m_to_next_exit.png"),
    answerOptions: [
      {
        answerText: "200m to next exit",
        isCorrect: true,
      },
      {
        answerText: "100m to next exit",
        isCorrect: false,
      },
      {
        answerText: "End of motorway",
        isCorrect: false,
      },
      {
        answerText: "300m to next exit",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/motorway_signs/advance_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Motorway ahead",
        isCorrect: false,
      },
      {
        answerText: "Advance direction sign",
        isCorrect: true,
      },
      {
        answerText: "Route confirmatory sign with Euro Route marker plate.",
        isCorrect: false,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
    ],
  },
];

export default motorwayQuestions;
