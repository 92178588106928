const regulatoryQuestions = [
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_100kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/taxi_rank.png"),
    answerOptions: [
      {
        answerText: "Lay-by sign",
        isCorrect: false,
      },
      {
        answerText: "Taxi rank",
        isCorrect: true,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry_for_large_vehicles_weight.png"),
    answerOptions: [
      {
        answerText: "Maximum gross weight (traffic management)",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: true,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_u_turn.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No U-turn",
        isCorrect: true,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/height_restriction1.png"),
    answerOptions: [
      {
        answerText: "Safe height plate",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Height restriction",
        isCorrect: true,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/end_of_restriction_zone.png"),
    answerOptions: [
      {
        answerText: "End of restriction zone",
        isCorrect: true,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/zonal_restriction_no_parking_large_vehicles.png"),
    answerOptions: [
      {
        answerText: "End of the restriction zone",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Zonal restriction - no parking of large vehicles",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/disc_parking_plate.png"),
    answerOptions: [
      {
        answerText: "Pedestrianised street",
        isCorrect: false,
      },
      {
        answerText: "Disc parking plate",
        isCorrect: true,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "Parking permitted",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/parking_permitted1.png"),
    answerOptions: [
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "Parking permitted",
        isCorrect: true,
      },
      {
        answerText: "Lay-by",
        isCorrect: false,
      },
      {
        answerText: "End of the restriction zone",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/pedestrianised_street1.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Tram and access only street",
        isCorrect: false,
      },
      {
        answerText: "Pedestrianised street",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_overtaking.png"),
    answerOptions: [
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
      {
        answerText: "No overtaking",
        isCorrect: true,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "No entry",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/height_restriction.png"),
    answerOptions: [
      {
        answerText: "Height restriction",
        isCorrect: true,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Safe height plate",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_overtaking_for_three_axle_vehicles.png"),
    answerOptions: [
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No overtaking",
        isCorrect: false,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: false,
      },
      {
        answerText:
          "In a tunnel goods vehicles cannot use right-hand lane (by reference to number of axles)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/mini_roundabout_1.jpg"),
    answerOptions: [
      { answerText: "Dangerous bend ahead", isCorrect: false },
      { answerText: "Mini roundabout ahead", isCorrect: true },
      { answerText: "Loop road ahead", isCorrect: false },
      { answerText: "Roundabout ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/keep_left.png"),
    answerOptions: [
      {
        answerText: "Turn left",
        isCorrect: false,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: true,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/keep_right.png"),
    answerOptions: [
      {
        answerText: "Keep right",
        isCorrect: true,
      },
      {
        answerText: "Turn right",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/keep_straight_ahead.png"),
    answerOptions: [
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Straight ahead",
        isCorrect: true,
      },
      {
        answerText: "Two way traffic",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/pass_either_side.png"),
    answerOptions: [
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: true,
      },
      {
        answerText: "Start of a passing lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_left.png"),
    answerOptions: [
      {
        answerText: "Turn left",
        isCorrect: true,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_left_ahead.png"),
    answerOptions: [
      {
        answerText: "Turn left",
        isCorrect: false,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: true,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_right.png"),
    answerOptions: [
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
      {
        answerText: "Turn right",
        isCorrect: true,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_right_ahead.png"),
    answerOptions: [
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Turn right",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_per_lane.png"),
    answerOptions: [
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "Speed limit per lane",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry_pedestrians_tramway.png"),
    answerOptions: [
      {
        answerText: "No entry for pedestrians to tramway",
        isCorrect: true,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/start_of_cycle_track.png"),
    answerOptions: [
      {
        answerText: "Seperate bicycle and pedestrian lanes",
        isCorrect: false,
      },
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "Start of cycle track",
        isCorrect: true,
      },
      {
        answerText: "End of cycle track",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/end_of_cycle_track.png"),
    answerOptions: [
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "End of cycle track",
        isCorrect: true,
      },
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
      {
        answerText: "Start of cycle track",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/go.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/electronic_variable_speed_tunnel.png"),
    answerOptions: [
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: false,
      },
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Electronic variable speed limit sign (tunnel only)",
        isCorrect: true,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/variable_speed_limit.png"),
    answerOptions: [
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: false,
      },
      {
        answerText: "Variable speed limit",
        isCorrect: true,
      },
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/variable_speed_limit.png"),
    answerOptions: [
      {
        answerText:
          "In a tunnel goods vehicles cannot use right-hand lane (by reference to number of axles)",
        isCorrect: true,
      },
      {
        answerText: "Variable speed limit",
        isCorrect: false,
      },
      {
        answerText:
          "No entry to goods vehicles (by reference to number of axles)",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/electronic_periodic_speed_limit.png"),
    answerOptions: [
      {
        answerText:
          "In a tunnel goods vehicles cannot use right-hand lane (by reference to number of axles)",
        isCorrect: false,
      },
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: true,
      },
      {
        answerText: "Electronic periodic speed limit sign at school",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tram_only_street.png"),
    answerOptions: [
      {
        answerText: "Bus only street",
        isCorrect: false,
      },
      {
        answerText: "Tram and access only street",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "Tram only street",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/mobile_vms_displaying_chevrons.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying text message",
        isCorrect: false,
      },
      {
        answerText: "Direction change",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying chevrons",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/mobile_vms_displaying_text_message.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying text message",
        isCorrect: true,
      },
      {
        answerText: "Direction change",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying chevrons",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/vms_chevron_formats.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
      {
        answerText: "Examples of VMS chevron formats",
        isCorrect: true,
      },
      {
        answerText: "Motorway direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/overhead_variable_message_sign_vms.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: true,
      },
      {
        answerText: "Mobile VMS displaying text message",
        isCorrect: false,
      },
      {
        answerText: "Direction change",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying chevrons",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tram_and_access_only_street.png"),
    answerOptions: [
      {
        answerText: "Bus only street",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "Tram and access only street",
        isCorrect: true,
      },
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/bus_only_street.png"),
    answerOptions: [
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Tram only street",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
      {
        answerText: "Bus only street",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_back.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/pedestrians_and_bicycles_only.png"),
    answerOptions: [
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: true,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Seperate bicycle and pedestrian lanes",
        isCorrect: false,
      },
      {
        answerText: "Pedestrianised street",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/seperate_pedestrians_and_bicycles.png"),
    answerOptions: [
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Seperate bicycle and pedestrian lanes",
        isCorrect: true,
      },
      {
        answerText: "Pedestrianised street",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/move_into_left_lane.png"),
    answerOptions: [
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Move into the left-hand lane",
        isCorrect: true,
      },
      {
        answerText: "Turn left Ahead",
        isCorrect: false,
      },
      {
        answerText: "Turn left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/move_into_right_lane.png"),
    answerOptions: [
      {
        answerText: "Go (lane open)",
        isCorrect: false,
      },
      {
        answerText: "Turn left Ahead",
        isCorrect: false,
      },
      {
        answerText: "Move into the right-hand lane",
        isCorrect: true,
      },
      {
        answerText: "Move into the left-hand lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/go_lane_open.png"),
    answerOptions: [
      {
        answerText: "Go (lane open)",
        isCorrect: true,
      },
      {
        answerText: "Turn left Ahead",
        isCorrect: false,
      },
      {
        answerText: "Move into the right-hand lane",
        isCorrect: false,
      },
      {
        answerText: "Move into the left-hand lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/stop_lane_closed.png"),
    answerOptions: [
      {
        answerText: "Move into the left-hand lane",
        isCorrect: false,
      },
      {
        answerText: "Move into the right-hand lane",
        isCorrect: false,
      },
      {
        answerText: "Stop (lane closed)",
        isCorrect: true,
      },
      {
        answerText: "Go (lane open)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/electronic_periodic_speed_limit_at_school.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
      {
        answerText: "Electronic periodic speed limit sign at school",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/stop_circle.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: true,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "School wardens stop sign",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/go_irish.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go or Téigh",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry_to_goods_vehicles_axes.png"),
    answerOptions: [
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText:
          "No entry to goods vehicles (by reference to number of axles)",
        isCorrect: true,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/withflow_buslane_left.png"),
    answerOptions: [
      {
        answerText: "With flow bus lane on right",
        isCorrect: false,
      },
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on left",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/withflow_buslane_right.png"),
    answerOptions: [
      {
        answerText: "With flow bus lane on right",
        isCorrect: true,
      },
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/contraflow_buslane.png"),
    answerOptions: [
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
      {
        answerText: "Contra flow bus lane",
        isCorrect: true,
      },
      {
        answerText: "With flow bus lane on right",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tramlane_on_left.png"),
    answerOptions: [
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on left",
        isCorrect: true,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tramlane_on_right.png"),
    answerOptions: [
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: true,
      },
      {
        answerText: "Tram lane on left",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_120kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_30kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_50kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_60kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_80kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/stop.png"),
    answerOptions: [
      {
        answerText: "School wardens stop sign",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: true,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/clearway.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: true,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_bicycles.png"),
    answerOptions: [
      {
        answerText: "No ridden or accompanied horses",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
      {
        answerText: "No bicycles",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_vehicle_width.png"),
    answerOptions: [
      {
        answerText: "Height restriction",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: true,
      },
      {
        answerText: "Maximum vehicle length",
        isCorrect: false,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_vehicle_length.png"),
    answerOptions: [
      {
        answerText: "Height restriction",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle length",
        isCorrect: true,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_axle_weight.png"),
    answerOptions: [
      {
        answerText: "Maximum gross weight (traffic management)",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: true,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_gross_weight.png"),
    answerOptions: [
      {
        answerText: "Maximum gross weight (traffic management)",
        isCorrect: true,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: false,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_ridden_or_accompanied_horses.png"),
    answerOptions: [
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "No ridden or accompanied horses",
        isCorrect: true,
      },
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "No horse carriages",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_horse_carriages.png"),
    answerOptions: [
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "No ridden or accompanied horses",
        isCorrect: false,
      },
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "No horse carriages",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry.png"),
    answerOptions: [
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: true,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/max_speed_limit_80.png"),
    answerOptions: [
      {
        answerText: "Maximum speed limit 120kmh",
        isCorrect: false,
      },
      {
        answerText: "Maximum speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Maximum speed limit 80kmh",
        isCorrect: true,
      },
      {
        answerText: "Maximum speed limit 60kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_parking.png"),
    answerOptions: [
      {
        answerText: "Parking permitted",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: true,
      },
      {
        answerText: "End of the restriction zone",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/school_warden_stop_sign.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      {
        answerText: "School wardens stop sign",
        isCorrect: true,
      },
      {
        answerText: "School children crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_left_turn.png"),
    answerOptions: [
      {
        answerText: "No left turn",
        isCorrect: true,
      },
      {
        answerText: "No right turn",
        isCorrect: false,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/yield.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: true,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/yield_in_irish.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: true,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_right_turn.png"),
    answerOptions: [
      {
        answerText: "No left turn",
        isCorrect: false,
      },
      {
        answerText: "No right turn",
        isCorrect: true,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_straight_ahead.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No right turn",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: true,
      },
    ],
  },
];

export default regulatoryQuestions;
