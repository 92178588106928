const allQuestions = [
  {
    roadSignOption: require("./roadsigns/warning_road_signs/accompanied_horses_and_ponies.png"),
    answerOptions: [
      { answerText: "Cattle and farm animals", isCorrect: false },
      { answerText: "Accompanied horses and ponies", isCorrect: true },
      { answerText: "farm animals crossing", isCorrect: false },
      { answerText: "Sheep crossing", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dangerous_corner_ahead_left.png"),
    answerOptions: [
      { answerText: "Dangerous corner ahead", isCorrect: true },
      { answerText: "Turn left ahead", isCorrect: false },
      { answerText: "Dangerour bend ahead", isCorrect: false },
      { answerText: "Turn left", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/roundabout_ahead.png"),
    answerOptions: [
      { answerText: "Loop road", isCorrect: false },
      { answerText: "Roundabout ahead", isCorrect: true },
      { answerText: "Mini roundabout ahead", isCorrect: false },
      { answerText: "Series of Dangerous bends ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/cattle_and_farm_animals.png"),
    answerOptions: [
      { answerText: "Cattle and farm animals", isCorrect: true },
      { answerText: "Accompanied horses and ponies", isCorrect: false },
      { answerText: "Animals crossing", isCorrect: false },
      { answerText: "Sheep crossing", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/crossroads.png"),
    answerOptions: [
      { answerText: "Crossroads with dual carriage way", isCorrect: false },
      { answerText: "T junction", isCorrect: false },
      { answerText: "Merging traffic", isCorrect: false },
      { answerText: "Crossroads", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/crossroads_ahead_dual_cway.png"),
    answerOptions: [
      { answerText: "Crossroads with dual carriage way", isCorrect: true },
      { answerText: "T junction", isCorrect: false },
      { answerText: "Merging traffic", isCorrect: false },
      { answerText: "Crossroads", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/crosswinds.png"),
    answerOptions: [
      { answerText: "Low flying aircraft", isCorrect: false },
      { answerText: "Crosswinds", isCorrect: true },
      { answerText: "Level crossing ahead unguarded", isCorrect: false },
      { answerText: "Tramlane crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/cyclists.png"),
    answerOptions: [
      { answerText: "Pedestrians and bicycles Only", isCorrect: false },
      { answerText: "Motor cyclists", isCorrect: false },
      { answerText: "Cyclists", isCorrect: true },
      { answerText: "Slippery for cyclists", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dangerous_bend_ahead.png"),
    answerOptions: [
      { answerText: "Dangerous corner ahead", isCorrect: false },
      { answerText: "Turn left", isCorrect: false },
      { answerText: "Keep left", isCorrect: false },
      { answerText: "Dangerous bend ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dangerous_corner_ahead_left.png"),
    answerOptions: [
      { answerText: "Turn left", isCorrect: false },
      { answerText: "Dangerous corner ahead", isCorrect: true },
      { answerText: "Dangerous bend ahead", isCorrect: false },
      { answerText: "Series of dangerous corners ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/deer_or_wild_animals.png"),
    answerOptions: [
      { answerText: "Deer or wild animals", isCorrect: true },
      { answerText: "Sheep", isCorrect: false },
      { answerText: "Cattle and farm animals", isCorrect: false },
      { answerText: "Accompanied horses and ponies", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/dual_carriageway_ends.png"),
    answerOptions: [
      { answerText: "End of central reserve or obstruction", isCorrect: false },
      { answerText: "Obstruction between lanes", isCorrect: false },
      { answerText: "Dual carriage way ends", isCorrect: true },
      { answerText: "Road narrows on Both Sides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/lane_loss.png"),
    answerOptions: [
      { answerText: "Outside lane of Two Closed", isCorrect: false },
      { answerText: "Move to left", isCorrect: false },
      { answerText: "Road narrows from right", isCorrect: false },
      { answerText: "Lane Loss", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/level_crossing_ahead_guarded_by_gates_or_barrier.png"),
    answerOptions: [
      {
        answerText:
          "Level crossing ahead, guarded by gates or lifting barriers",
        isCorrect: true,
      },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barriers",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      { answerText: "Tram lane crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/level_crossing_ahead_unguarded_by_gates_or_barrier.png"),
    answerOptions: [
      {
        answerText:
          "Level crossing ahead, guarded by gates or lifting barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barriers",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/chevron_board_left_sharp_change_of_direction.png"),
    answerOptions: [
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: false,
      },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: true,
      },
      {
        answerText: "Barrier board",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/chevron_board_right_sharp_change_of_direction.png"),
    answerOptions: [
      {
        answerText: "Barrier board",
        isCorrect: false,
      },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the right)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/level_crossing_ahead_with_lights_barriers.png"),
    answerOptions: [
      {
        answerText:
          "Level crossing ahead, guarded by gates or lifting barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: true,
      },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barriers",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/loop_road_ahead.png"),
    answerOptions: [
      { answerText: "Dangerous bend ahead", isCorrect: false },
      { answerText: "Loop road ahead", isCorrect: true },
      { answerText: "U - turn ahead", isCorrect: false },
      { answerText: "Roundabout ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/low_flying_aircraft.png"),
    answerOptions: [
      { answerText: "Crosswinds", isCorrect: false },
      { answerText: "Direction sign", isCorrect: false },
      { answerText: "Low flying aircraft", isCorrect: true },
      { answerText: "Alternative Route Heavy Vehicles", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/merging_diverging_traffic.png"),
    answerOptions: [
      { answerText: "Merging traffic", isCorrect: false },
      { answerText: "Crossroads ahead", isCorrect: false },
      { answerText: "Y-junction", isCorrect: false },
      { answerText: "Merging/diverging traffic", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/merging_traffic.png"),
    answerOptions: [
      { answerText: "Merging traffic", isCorrect: true },
      { answerText: "Crossroads ahead", isCorrect: false },
      { answerText: "Y-junction", isCorrect: false },
      { answerText: "Merging/diverging traffic", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/mini_roundabout_ahead.png"),
    answerOptions: [
      { answerText: "Roundabout ahead", isCorrect: false },
      { answerText: "Loop road ahead", isCorrect: false },
      { answerText: "Mini roundabout ahead", isCorrect: true },
      { answerText: "Dangerous bend ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/overhead_electric_cables.png"),
    answerOptions: [
      { answerText: "Crosswinds", isCorrect: false },
      { answerText: "Overhead electric cables", isCorrect: true },
      { answerText: "Tram lane crossing ahead", isCorrect: false },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/drive-on-left_sign.png"),
    answerOptions: [
      {
        answerText: "Drive on left",
        isCorrect: true,
      },
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "No overtaking",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/Pedestrian_crossing_ahead.png"),
    answerOptions: [
      { answerText: "School ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      { answerText: "Pedestrian crossing ahead", isCorrect: true },
      { answerText: "School Children crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_divides.png"),
    answerOptions: [
      { answerText: "Road divides", isCorrect: true },
      { answerText: "Obstruction between lanes", isCorrect: false },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
      { answerText: "Two Way traffic", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_narrows_from_left_yel.png"),
    answerOptions: [
      { answerText: "Road narrows from left", isCorrect: true },
      { answerText: "Road narrows on both sides", isCorrect: false },
      { answerText: "Road narrows from right", isCorrect: false },
      { answerText: "Road divides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_narrows_from_right_yel.png"),
    answerOptions: [
      { answerText: "Road narrows from left", isCorrect: false },
      { answerText: "Road narrows on both sides", isCorrect: false },
      { answerText: "Road narrows from right", isCorrect: true },
      { answerText: "Road divides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/road_narrows_on_both_sides_yel.png"),
    answerOptions: [
      { answerText: "Road narrows from left", isCorrect: false },
      { answerText: "Road narrows on both sides", isCorrect: true },
      { answerText: "Road narrows from right", isCorrect: false },
      { answerText: "Road divides", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/school_ahead.png"),
    answerOptions: [
      { answerText: "Pedestrian crossing ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      { answerText: "School ahead", isCorrect: true },
      { answerText: "School children crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/caution_children_crossing_residential.png"),
    answerOptions: [
      { answerText: "Pedestrian crossing ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: true,
      },
      { answerText: "School ahead", isCorrect: false },
      { answerText: "School children crossing ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/school_children_crossing_ahead.png"),
    answerOptions: [
      { answerText: "Pedestrian crossing ahead", isCorrect: false },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      { answerText: "School ahead", isCorrect: false },
      { answerText: "School children crossing ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/series_of_bumps_or_hollows_ahead.png"),
    answerOptions: [
      { answerText: "Sharp dip ahead", isCorrect: false },
      {
        answerText: "Series of bumps or hollows ahead",
        isCorrect: true,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: false },
      { answerText: "Uneven surface", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/series_of_dangerous_bends_ahead.png"),
    answerOptions: [
      { answerText: "Series of dangerous corners ahead", isCorrect: false },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      { answerText: "Dangerous corner ahead", isCorrect: false },
      { answerText: "Series of dangerous bends ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/series_of_dangerous_corners_ahead.png"),
    answerOptions: [
      { answerText: "Series of dangerous corners ahead", isCorrect: true },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
      { answerText: "Dangerous corner ahead", isCorrect: false },
      { answerText: "Series of dangerous bends ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sharp_dip_ahead.png"),
    answerOptions: [
      { answerText: "Series of bumps or hollows ahead", isCorrect: false },
      {
        answerText: "Sharp dip ahead",
        isCorrect: true,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: false },
      { answerText: "Unever surface", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sharp_rise_ahead_eg_humpback-bridge.png"),
    answerOptions: [
      { answerText: "Sharp dip ahead", isCorrect: false },
      {
        answerText: "Unever surface",
        isCorrect: false,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: true },
      { answerText: "Series of bumps or hollows ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sheep.png"),
    answerOptions: [
      { answerText: "Cattle and farm animals", isCorrect: false },
      {
        answerText: "Sheep",
        isCorrect: true,
      },
      { answerText: "Deer or wild animals", isCorrect: false },
      { answerText: "accompanied horses or ponies", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/sideroad.png"),
    answerOptions: [
      { answerText: "Crossroads", isCorrect: false },
      {
        answerText: "Merging traffic",
        isCorrect: false,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Sideroad", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/slippery_for_cyclists.png"),
    answerOptions: [
      { answerText: "Slippery for cyclists", isCorrect: true },
      {
        answerText: "Uneven surface",
        isCorrect: false,
      },
      { answerText: "Slippery road ahead", isCorrect: false },
      { answerText: "Cyclists", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/slippery_road_ahead.png"),
    answerOptions: [
      { answerText: "Uneven surface", isCorrect: false },
      {
        answerText: "Slippery for cyclists",
        isCorrect: false,
      },
      { answerText: "Slippery road ahead", isCorrect: true },
      { answerText: "Uneven surface", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/slow_automatic_level_crossing.png"),
    answerOptions: [
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText: "Automatic level crossing ahead",
        isCorrect: true,
      },
      { answerText: "Stop when lights are red", isCorrect: false },
      {
        answerText:
          "Level crossing ahead, unguarded by gates or lifting barrier",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/staggered_crossroads.png"),
    answerOptions: [
      { answerText: "Crossroads", isCorrect: false },
      {
        answerText: "Side road",
        isCorrect: false,
      },
      { answerText: "Staggered crossroads", isCorrect: true },
      { answerText: "T-junction", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/start_of_climbing_lane.png"),
    answerOptions: [
      { answerText: "Lane loss", isCorrect: false },
      {
        answerText: "Start of passing lane",
        isCorrect: false,
      },
      { answerText: "Two way traffic flow", isCorrect: false },
      { answerText: "Start of climbing lane", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/start_of_passing_lane.png"),
    answerOptions: [
      { answerText: "Start of climbing lane", isCorrect: false },
      {
        answerText: "Diverging traffic",
        isCorrect: false,
      },
      { answerText: "Start of passing lane", isCorrect: true },
      { answerText: "Dual carriage way ends", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/steep_ascent_ahead.png"),
    answerOptions: [
      { answerText: "Steep ascent ahead", isCorrect: true },
      {
        answerText: "Sharp rise ahead eg humpback bridge",
        isCorrect: false,
      },
      { answerText: "Steep descent ahead", isCorrect: false },
      { answerText: "Slippery road ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/steep_decent_ahead.png"),
    answerOptions: [
      { answerText: "Steep ascent ahead", isCorrect: false },
      {
        answerText: "Slippery road ahead",
        isCorrect: false,
      },
      { answerText: "Sharp rise ahead eg humpback bridge", isCorrect: false },
      { answerText: "Steep descent ahead", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/stop_when_red_lights_show.png"),
    answerOptions: [
      { answerText: "Stop", isCorrect: false },
      {
        answerText: "Yield",
        isCorrect: false,
      },
      { answerText: "Stop when red lights show", isCorrect: true },
      { answerText: "Traffic signals ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/T_junction_ahead.png"),
    answerOptions: [
      { answerText: "Crossroads", isCorrect: false },
      {
        answerText: "T-junction ahead",
        isCorrect: true,
      },
      { answerText: "T-junction with dual carriage way", isCorrect: false },
      { answerText: "Sideroad", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/traffic_crossover_ahead.jpeg"),
    answerOptions: [
      { answerText: "Traffic crossover ahead", isCorrect: true },
      {
        answerText: "T-junction ahead",
        isCorrect: false,
      },
      { answerText: "T-junction with dual carriage way", isCorrect: false },
      { answerText: "Sideroad", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/T_junction_ahead_dual_cway.png"),
    answerOptions: [
      { answerText: "T-junction with dual carriage way", isCorrect: true },
      {
        answerText: "T-junction ahead",
        isCorrect: false,
      },
      { answerText: "Crossroads with dual carriage way", isCorrect: false },
      { answerText: "Crossroads", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/traffic_signals_ahead.png"),
    answerOptions: [
      { answerText: "Stop", isCorrect: false },
      {
        answerText: "Stop when lights are red",
        isCorrect: false,
      },
      { answerText: "Traffic signals ahead", isCorrect: true },
      { answerText: "Yield", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tram_warning_look_both_ways.png"),
    answerOptions: [
      { answerText: "Tram lane crossing ahead", isCorrect: false },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      { answerText: "Automatic level crossing ahead", isCorrect: false },
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tram_warning_look_left.png"),
    answerOptions: [
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: true,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      { answerText: "Automatic level crossing ahead", isCorrect: false },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tram_warning_look_right.png"),
    answerOptions: [
      {
        answerText: "Automatic level crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: true,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tramlane_crossing_ahead.png"),
    answerOptions: [
      {
        answerText: "Automatic level crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Level crossing ahead with lights and barriers",
        isCorrect: false,
      },
      {
        answerText: "Tram lane warning signs for pedestrians",
        isCorrect: false,
      },
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/falling_rocks.jpg"),
    answerOptions: [
      {
        answerText: "Falling rocks",
        isCorrect: true,
      },
      {
        answerText: "Steep descent ahead",
        isCorrect: false,
      },
      {
        answerText: "Unprotected quay, canal or river",
        isCorrect: false,
      },
      {
        answerText: "Dangerous bend ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/tunnel_ahead.png"),
    answerOptions: [
      {
        answerText: "Tram lane crossing ahead",
        isCorrect: false,
      },
      {
        answerText: "Tunnel ahead",
        isCorrect: true,
      },
      {
        answerText: "No overtaking",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/two-way-traffic_yellow.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Two way traffic",
        isCorrect: true,
      },
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
      {
        answerText: "Straight ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/low_bridge_ahead.png"),
    answerOptions: [
      {
        answerText: "Steep descent ahead",
        isCorrect: false,
      },
      {
        answerText: "Low bridge ahead",
        isCorrect: true,
      },
      {
        answerText: "Unprotected quay, canal or river",
        isCorrect: false,
      },
      {
        answerText: "Sharp rise ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/unprotected_quay_canal_river.png"),
    answerOptions: [
      {
        answerText: "Steep descent ahead",
        isCorrect: false,
      },
      {
        answerText: "Crosswinds",
        isCorrect: false,
      },
      {
        answerText: "Unprotected quay, canal or river",
        isCorrect: true,
      },
      {
        answerText: "Sharp rise ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/warning_road_signs/y-junction.png"),
    answerOptions: [
      {
        answerText: "Y-junction",
        isCorrect: true,
      },
      {
        answerText: "Diverging traffic",
        isCorrect: false,
      },
      {
        answerText: "Merging traffic",
        isCorrect: false,
      },
      {
        answerText: "T-junction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_ahead_construction.png"),
    answerOptions: [
      {
        answerText: "Detour ahead",
        isCorrect: true,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Town or village sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_destination.png"),
    answerOptions: [
      {
        answerText: "Town or village sign",
        isCorrect: false,
      },
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Detour Destination",
        isCorrect: true,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_to_left_construction.jpg"),
    answerOptions: [
      {
        answerText: "Detour to left",
        isCorrect: true,
      },
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Detour Destination",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/detour_to_right_construction.jpg"),
    answerOptions: [
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Detour to right",
        isCorrect: true,
      },
      {
        answerText: "Detour Destination",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_left_construction.png"),
    answerOptions: [
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic left",
        isCorrect: true,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_right_construction.png"),
    answerOptions: [
      {
        answerText: "Detour to right",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic left",
        isCorrect: false,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic right",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_alt_construction.png"),
    answerOptions: [
      {
        answerText: "Diverted traffic",
        isCorrect: true,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/diverted_traffic_alt1_construction.png"),
    answerOptions: [
      {
        answerText: "Detour to left",
        isCorrect: false,
      },
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: true,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/lanes_rejoin_at_crossover.png"),
    answerOptions: [
      {
        answerText: "Lanes rejoin at crossover",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
      {
        answerText: "Lanes rejoin at crossover",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/lanes_diverge_at_crossover.png"),
    answerOptions: [
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: true,
      },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
      {
        answerText: "Lanes rejoin at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end_of_central_reserve_or_obstruction_construction.png"),
    answerOptions: [
      {
        answerText: "End of obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "End of central reserve or obstruction",
        isCorrect: true,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end_of_detour_construction.png"),
    answerOptions: [
      {
        answerText: "End",
        isCorrect: false,
      },
      {
        answerText: "Detour to right",
        isCorrect: false,
      },
      {
        answerText: "End of detour",
        isCorrect: true,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end_of_obstruction_between_lanes_construction.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "End of central reserve or obstruction",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "End of obstruction between lanes",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/flagman_ahead_construction.png"),
    answerOptions: [
      {
        answerText: "School ahead",
        isCorrect: false,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: true,
      },
      {
        answerText: "Roadworks ahead",
        isCorrect: false,
      },
      {
        answerText: "Pedestrians cross to right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/hump_or_ramp_construction.png"),
    answerOptions: [
      { answerText: "Sharp dip ahead", isCorrect: false },
      {
        answerText: "Unever surface",
        isCorrect: false,
      },
      { answerText: "Hump or ramp", isCorrect: true },
      { answerText: "Series of bumps or hollows ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_left_one_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: true,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Move to right (one lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_left_two_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Road narrows from right",
        isCorrect: false,
      },
      {
        answerText: "Two-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Move to left (two lane)",
        isCorrect: true,
      },
      {
        answerText: "Move to right (two lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_right_one_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Move to right (one lane)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/move_to_right_two_lane_construction.png"),
    answerOptions: [
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
      {
        answerText: "Two-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Move to left (two lane)",
        isCorrect: false,
      },
      {
        answerText: "Move to right (two lane)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/nearside_lane_closed2.png"),
    answerOptions: [
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: true,
      },
      {
        answerText: "Two way traffic",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/nearside_lane_closed3.png"),
    answerOptions: [
      {
        answerText: "Outside lane (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Two way traffic",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: true,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/obstruction_between_lanes_construction.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: true,
      },
      {
        answerText: "End of central reserve or obstruction",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "Start of central reserve or obstruction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/offside_lane_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of four) closed",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of four) closed",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/nearside_lane_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of four) closed",
        isCorrect: true,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of four) closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/queues_likely.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Queues likely",
        isCorrect: true,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/loose_chippings.png"),
    answerOptions: [
      {
        answerText: "Loose chippings",
        isCorrect: true,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Uneven surface",
        isCorrect: false,
      },
      {
        answerText: "Hump or ramp",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/one-lane_crossover_back_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: true,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/one-lane_crossover_out_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (out)",
        isCorrect: true,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/outside_lane_closed_2.png"),
    answerOptions: [
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: true,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/outside_lane_closed_3.png"),
    answerOptions: [
      {
        answerText: "Outside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Outside lane (of three) closed",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/overhead_electric_cables_construction.png"),
    answerOptions: [
      { answerText: "Crosswinds", isCorrect: false },
      { answerText: "Overhead Electric Cables", isCorrect: true },
      { answerText: "Tram Lane Crossing Ahead", isCorrect: false },
      {
        answerText: "Level Crossing Ahead with Lights and Barriers",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/pedestrian_cross_to_left_construction.png"),
    answerOptions: [
      {
        answerText: "Pedestrians cross to left",
        isCorrect: true,
      },
      {
        answerText: "Pedestrians cross to right",
        isCorrect: false,
      },
      {
        answerText: "Site access on left",
        isCorrect: false,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/pedestrian_cross_to_right_construction.png"),
    answerOptions: [
      {
        answerText: "Pedestrians cross to left",
        isCorrect: false,
      },
      {
        answerText: "Pedestrians cross to right",
        isCorrect: true,
      },
      {
        answerText: "Site access on right",
        isCorrect: false,
      },
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Road closed",
        isCorrect: true,
      },
      {
        answerText: "Detour ahead",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "Roadworks ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_narrows_on_both_sides.png"),
    answerOptions: [
      {
        answerText: "End of obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Road narrows from right",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "Road narrows on both sides",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_narrows_on_left.png"),
    answerOptions: [
      {
        answerText: "Road narrows on left",
        isCorrect: true,
      },
      {
        answerText: "Road narrows from right",
        isCorrect: false,
      },
      {
        answerText: "Move to right (one lane)",
        isCorrect: false,
      },
      {
        answerText: "Road narrows on both sides",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/road_narrows_on_right.png"),
    answerOptions: [
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
      {
        answerText: "Road narrows from right",
        isCorrect: true,
      },
      {
        answerText: "Move to rileft (one lane)",
        isCorrect: false,
      },
      {
        answerText: "Road narrows on both sides",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/distance.png"),
    answerOptions: [
      {
        answerText: "Distance",
        isCorrect: true,
      },
      {
        answerText: "Direction and distance",
        isCorrect: false,
      },
      {
        answerText: "Direction",
        isCorrect: false,
      },
      {
        answerText: "Destination and distance sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/direction.png"),
    answerOptions: [
      {
        answerText: "Direction and distance",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Direction",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/direction_and_distance.png"),
    answerOptions: [
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Direction and distance",
        isCorrect: true,
      },
      {
        answerText: "Direction",
        isCorrect: false,
      },
      {
        answerText: "Destination and distance sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/end.png"),
    answerOptions: [
      {
        answerText: "End of detour",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "No U-turn",
        isCorrect: false,
      },
      {
        answerText: "End",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/consealed_entrance.png"),
    answerOptions: [
      {
        answerText: "Slow",
        isCorrect: false,
      },
      {
        answerText: "Sideroad",
        isCorrect: false,
      },
      {
        answerText: "Concealed entrance",
        isCorrect: true,
      },
      {
        answerText: "Cautionary speed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/use_hard_shoulder.png"),
    answerOptions: [
      {
        answerText: "Unfinished road surface",
        isCorrect: false,
      },
      {
        answerText: "Use hard shoulder",
        isCorrect: true,
      },
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
      {
        answerText: "Types of works",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/speed_limit_ahead.png"),
    answerOptions: [
      {
        answerText: "Speed limit ahead",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Slow",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/go.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/stop_circle.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: true,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "School wardens stop sign",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/go_irish.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go or Téigh",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/barrier_board.png"),
    answerOptions: [
      {
        answerText: "Barrier board",
        isCorrect: true,
      },
      {
        answerText: "Chevron board",
        isCorrect: false,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the left)",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/chevron_board.png"),
    answerOptions: [
      {
        answerText: "Barrier board",
        isCorrect: false,
      },
      {
        answerText: "Chevron board",
        isCorrect: true,
      },
      {
        answerText: "Chevron board (a sharp change of direction to the right)",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/hard_shoulder_closed.jpg"),
    answerOptions: [
      {
        answerText: "Use hard shoulder",
        isCorrect: false,
      },
      {
        answerText: "Unfinished road surface",
        isCorrect: false,
      },
      {
        answerText: "Types of works",
        isCorrect: true,
      },
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/unfinished_road_surface.jpg"),
    answerOptions: [
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
      {
        answerText: "Types of works",
        isCorrect: false,
      },
      {
        answerText: "Unfinished road surface",
        isCorrect: true,
      },
      {
        answerText: "Use hard shoulder",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/type_of_works.png"),
    answerOptions: [
      {
        answerText: "Unfinished road surface",
        isCorrect: false,
      },
      {
        answerText: "Concealed entrance",
        isCorrect: false,
      },
      {
        answerText: "Hard shoulder closed",
        isCorrect: false,
      },
      {
        answerText: "Type of works",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/slow.png"),
    answerOptions: [
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "Cautionary speed",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Slow",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/cautionary_speed.png"),
    answerOptions: [
      {
        answerText: "Cautionary speed",
        isCorrect: true,
      },
      {
        answerText: "Speed limit ahead",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Distance",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/lenght.png"),
    answerOptions: [
      {
        answerText: "Length",
        isCorrect: true,
      },
      {
        answerText: "Distance",
        isCorrect: false,
      },
      {
        answerText: "Direction",
        isCorrect: false,
      },
      {
        answerText: "Speed limit ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/roadworks_ahead.png"),
    answerOptions: [
      {
        answerText: "Flagman ahead",
        isCorrect: false,
      },
      {
        answerText: "Site access on right",
        isCorrect: false,
      },
      {
        answerText: "Roadworks ahead",
        isCorrect: true,
      },
      {
        answerText: "Pedestrian crossing to right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/side_road_left_construction.png"),
    answerOptions: [
      { answerText: "Site access on left", isCorrect: false },
      {
        answerText: "Side road on right",
        isCorrect: false,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on left", isCorrect: true },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/side_road_right_construction.png"),
    answerOptions: [
      { answerText: "Site access on right", isCorrect: false },
      {
        answerText: "Side road on right",
        isCorrect: true,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on left", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/single_lane_shuttle_working.png"),
    answerOptions: [
      {
        answerText: "Straight ahead",
        isCorrect: false,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "Diverted traffic",
        isCorrect: false,
      },
      {
        answerText: "Single lane (for shuttle working)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/site_access_on_left_construction.png"),
    answerOptions: [
      { answerText: "Site access on left", isCorrect: true },
      {
        answerText: "Site access on right",
        isCorrect: false,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on left", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/site_access_on_right_construction.png"),
    answerOptions: [
      { answerText: "Site access on left", isCorrect: true },
      {
        answerText: "Site access on right",
        isCorrect: true,
      },
      { answerText: "T-junction", isCorrect: false },
      { answerText: "Side road on right", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/slippery_road_ahead_construction.png"),
    answerOptions: [
      {
        answerText: "Uneven surface",
        isCorrect: false,
      },
      {
        answerText: "Loose chippings",
        isCorrect: false,
      },
      {
        answerText: "Slippery road",
        isCorrect: true,
      },
      {
        answerText: "Slippery for cyclists",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/start_of_central_reserve_or_obstruction_construction.png"),
    answerOptions: [
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Start of central reserve or obstruction between lanes",
        isCorrect: true,
      },
      {
        answerText: "End of obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/temporary_traffic_signal_ahead.png"),
    answerOptions: [
      {
        answerText: "Traffic lights ahead",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
      {
        answerText: "Temporary traffic lights ahead",
        isCorrect: true,
      },
      {
        answerText: "Slow",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_lane_crossover_back_construction.png"),
    answerOptions: [
      {
        answerText: "Move to left (two lanes)",
        isCorrect: false,
      },
      {
        answerText: "Two-lanes crossover (back)",
        isCorrect: true,
      },
      {
        answerText: "Two-lanes crossover (out)",
        isCorrect: false,
      },
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_lane_crossover_out_construction.png"),
    answerOptions: [
      {
        answerText: "Lanes diverge at crossover",
        isCorrect: false,
      },
      {
        answerText: "Two-lanes crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Two-lanes crossover (out)",
        isCorrect: true,
      },
      {
        answerText: "Move to right (two lanes)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_nearside_closed_3.png"),
    answerOptions: [
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Two offside lanes (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_nearside_lanes_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Two nearside lanes (of four) closed",
        isCorrect: true,
      },
      {
        answerText: "Two offside lanes (of four) closed",
        isCorrect: false,
      },
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_offside_lanes_of_four_closed_construction.png"),
    answerOptions: [
      {
        answerText: "Lane loss",
        isCorrect: false,
      },
      {
        answerText: "Two offside lanes (of four) closed",
        isCorrect: true,
      },
      {
        answerText: "Two nearside lanes (of four) closed",
        isCorrect: false,
      },
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two_outside_closed_3.png"),
    answerOptions: [
      {
        answerText: "Two nearside lanes (of three) closed",
        isCorrect: false,
      },
      {
        answerText: "Nearside lane (of two) closed",
        isCorrect: false,
      },
      {
        answerText: "Two offside lanes (of three) closed",
        isCorrect: true,
      },
      {
        answerText: "Nearside lane (of three) closed",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/two-way-traffic.png"),
    answerOptions: [
      {
        answerText: "Two way traffic",
        isCorrect: true,
      },
      {
        answerText: "Obstruction between lanes",
        isCorrect: false,
      },
      {
        answerText: "Straight Ahead",
        isCorrect: false,
      },
      {
        answerText: "Road narrows from left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/construction_signs/uneven_surface_construction.png"),
    answerOptions: [
      {
        answerText: "Uneven surface",
        isCorrect: true,
      },
      {
        answerText: "Hump or ramp",
        isCorrect: false,
      },
      {
        answerText: "Slippery road",
        isCorrect: false,
      },
      {
        answerText: "Loose chippings",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/dublin_port_tunnel_ahead.png"),
    answerOptions: [
      {
        answerText: "Distance sign",
        isCorrect: false,
      },
      {
        answerText: "Dublin port tunnel ahead",
        isCorrect: true,
      },
      {
        answerText: "Tunnel ahead",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/lane_destination_sign.png"),
    answerOptions: [
      {
        answerText: "Regional road",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Lan direction sign",
        isCorrect: true,
      },
      {
        answerText: "Destination direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/destination_distance_sign.png"),
    answerOptions: [
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Town or village sign",
        isCorrect: false,
      },
      {
        answerText: "Direction and distance",
        isCorrect: false,
      },
      {
        answerText: "Destination distance sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/sos_lay-by.png"),
    answerOptions: [
      {
        answerText: "Lay-by ahead",
        isCorrect: false,
      },
      {
        answerText: "SOS lay-by",
        isCorrect: true,
      },
      {
        answerText: "Speed camera",
        isCorrect: false,
      },
      {
        answerText: "Hospital ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/speedcamera_ahead.jpg"),
    answerOptions: [
      {
        answerText: "Hospital ahead",
        isCorrect: false,
      },
      {
        answerText: "SOS lay-by",
        isCorrect: false,
      },
      {
        answerText: "Speed camera",
        isCorrect: true,
      },
      {
        answerText: "Lay-by ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advanced_information_sign_for_low_clearance.png"),
    answerOptions: [
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
      {
        answerText: "Advanced information sign for low clearance",
        isCorrect: true,
      },
      {
        answerText: "Tunnel ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/traffic_calming_sign.png"),
    answerOptions: [
      {
        answerText: "Slow",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "School children crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/traffic_calming_sign1.jpg"),
    answerOptions: [
      {
        answerText: "Speed limit change ahead",
        isCorrect: false,
      },
      {
        answerText: "Speed camera ahead",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: true,
      },
      {
        answerText: "School children crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/supplementary_plate.jpg"),
    answerOptions: [
      {
        answerText: "Supplementary plate",
        isCorrect: true,
      },
      {
        answerText: "Speed limit change ahead",
        isCorrect: false,
      },
      {
        answerText: "Lay-by",
        isCorrect: false,
      },
      {
        answerText: "Slow lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/ramps.png"),
    answerOptions: [
      {
        answerText: "Ramps on road",
        isCorrect: true,
      },
      {
        answerText: "Speed limit change ahead",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: false,
      },
      {
        answerText: "Slow lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advance_sign_lay-by_tourism_facilities.png"),
    answerOptions: [
      {
        answerText: "Advance sign for lay-by with tourism information",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance sign for facilities in lay-by",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advance_direction_to_local_services.png"),
    answerOptions: [
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance sign for facilities in lay-by",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: true,
      },
      {
        answerText: "Alternative route off motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/sign_to_approved_tourist_information_points.png"),
    answerOptions: [
      {
        answerText: "Tourist attraction direction sign",
        isCorrect: false,
      },
      {
        answerText: "Sign to approved tourist information points",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/tourist_advanced_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Sign to approved tourist information",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/pedestrian_sign_to_tourist_attraction.jpg"),
    answerOptions: [
      {
        answerText: "Sign to approved tourist information",
        isCorrect: false,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/alternative_route_off_motorway.jpg"),
    answerOptions: [
      {
        answerText: "Alternative route off the motorway",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/tourist_attraction_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Sign to approved tourist information",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Tourist attraction direction sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/signing_to_approved_tourist_information.png"),
    answerOptions: [
      {
        answerText: "Tourist attraction direction sign",
        isCorrect: false,
      },
      {
        answerText: "Sign to approved tourist information points",
        isCorrect: true,
      },
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/sign_for_oige_youth_hostels.png"),
    answerOptions: [
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
      {
        answerText: "Sign for Óige youth hostels",
        isCorrect: true,
      },
      {
        answerText: "Pedestrian sign to a tourist attraction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/advance_sign_facilities_in_lay-by.png"),
    answerOptions: [
      {
        answerText: "Tourist advanced direction sign",
        isCorrect: false,
      },
      {
        answerText: "Advance sign for facilities in lay-by",
        isCorrect: true,
      },
      {
        answerText: "Advance direction to local services",
        isCorrect: false,
      },
      {
        answerText: "Alternative route off motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/alternative_route_heavy_vehicles.png"),
    answerOptions: [
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: true,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/alternative_route_high_vehicles.png"),
    answerOptions: [
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: true,
      },
      {
        answerText: "Alternative route for heavy vehicles",
        isCorrect: false,
      },
      {
        answerText: "Height restriction",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/speed_limit_ahead.jpg"),
    answerOptions: [
      {
        answerText: "Speed limit ahead",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Slow",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/town_or_village_sign.png"),
    answerOptions: [
      {
        answerText: "Town or village sign",
        isCorrect: true,
      },
      {
        answerText: "Destination distance sign",
        isCorrect: false,
      },
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Detour destination",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/slow_lane_sign.png"),
    answerOptions: [
      {
        answerText: "Slow lane sign",
        isCorrect: true,
      },
      {
        answerText: "Lane Loss",
        isCorrect: false,
      },
      {
        answerText: "One-lane crossover (back)",
        isCorrect: false,
      },
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/cul_de_sac.png"),
    answerOptions: [
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Lane Loss",
        isCorrect: false,
      },
      {
        answerText: "Cul-de-sac",
        isCorrect: true,
      },
      {
        answerText: "Move to left (one lane)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/industrial_estate.png"),
    answerOptions: [
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Lane Loss",
        isCorrect: false,
      },
      {
        answerText: "Industrial estate symbol",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/disabled_persons_parking.png"),
    answerOptions: [
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Disabled persons parking bay",
        isCorrect: true,
      },
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/airport.png"),
    answerOptions: [
      {
        answerText: "Airport symbol",
        isCorrect: true,
      },
      {
        answerText: "Disabled persons parking bay",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/hospital_ahead.png"),
    answerOptions: [
      {
        answerText: "Lay-by ahead sign",
        isCorrect: false,
      },
      {
        answerText: "Hospital ahead",
        isCorrect: true,
      },
      {
        answerText: "Hospital",
        isCorrect: false,
      },
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/hospital_sign.png"),
    answerOptions: [
      {
        answerText: "Lay-by ahead sign",
        isCorrect: false,
      },
      {
        answerText: "Hospital ahead",
        isCorrect: false,
      },
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
      {
        answerText: "Hospital",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/lay-by_ahead_sign.png"),
    answerOptions: [
      {
        answerText: "SOS lay-by",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: false,
      },
      {
        answerText: "Advanced sign for lay-by with tourism information",
        isCorrect: false,
      },
      {
        answerText: "Lay-by ahead sign",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/lay_by.png"),
    answerOptions: [
      {
        answerText: "Advanced sign for lay-by with tourism information",
        isCorrect: false,
      },
      {
        answerText: "Lay-by",
        isCorrect: false,
      },
      {
        answerText: "SOS lay-by",
        isCorrect: false,
      },
      {
        answerText: "Pedestrian sign to a car park",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/ferry_symbol.png"),
    answerOptions: [
      {
        answerText: "Cul-de-sac",
        isCorrect: false,
      },
      {
        answerText: "Ferry symbol",
        isCorrect: true,
      },
      {
        answerText: "Airport symbol",
        isCorrect: false,
      },
      {
        answerText: "Slow lane sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/local_road_direction_sign.png"),
    answerOptions: [
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: true,
      },
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/regional_road_direction_sign.png"),
    answerOptions: [
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: true,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/motorway_sign1.png"),
    answerOptions: [
      {
        answerText: "Advanced direction sign",
        isCorrect: true,
      },
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "Motorway ahead",
        isCorrect: false,
      },
      {
        answerText: "Regional road",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/national_road.png"),
    answerOptions: [
      {
        answerText: "National road",
        isCorrect: true,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Regional road",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/national_road_small.png"),
    answerOptions: [
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "National road",
        isCorrect: true,
      },
      {
        answerText: "Regional road",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/regional_road.png"),
    answerOptions: [
      {
        answerText: "National road",
        isCorrect: false,
      },
      {
        answerText: "Motorway",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Regional road",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/motorway_direction_sign.jpg"),
    answerOptions: [
      {
        answerText: "Motorway direction sign",
        isCorrect: true,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "National road direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/information_road_signs/national_road_direction_signs.png"),
    answerOptions: [
      {
        answerText: "Local road direction sign",
        isCorrect: false,
      },
      {
        answerText: "National road direction sign",
        isCorrect: true,
      },
      {
        answerText: "Regional road direction sign",
        isCorrect: false,
      },
      {
        answerText: "Motorway direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_100kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/taxi_rank.png"),
    answerOptions: [
      {
        answerText: "Lay-by sign",
        isCorrect: false,
      },
      {
        answerText: "Taxi rank",
        isCorrect: true,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry_for_large_vehicles_weight.png"),
    answerOptions: [
      {
        answerText: "Maximum gross weight (traffic management)",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: true,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_u_turn.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No U-turn",
        isCorrect: true,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/height_restriction1.png"),
    answerOptions: [
      {
        answerText: "Safe height plate",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Height restriction",
        isCorrect: true,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/end_of_restriction_zone.png"),
    answerOptions: [
      {
        answerText: "End of restriction zone",
        isCorrect: true,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/zonal_restriction_no_parking_large_vehicles.png"),
    answerOptions: [
      {
        answerText: "End of the restriction zone",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No entry for large vehicles (by reference to weight)",
        isCorrect: false,
      },
      {
        answerText: "Zonal restriction - no parking of large vehicles",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/disc_parking_plate.png"),
    answerOptions: [
      {
        answerText: "Pedestrianised street",
        isCorrect: false,
      },
      {
        answerText: "Disc parking plate",
        isCorrect: true,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "Parking permitted",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/parking_permitted1.png"),
    answerOptions: [
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "Parking permitted",
        isCorrect: true,
      },
      {
        answerText: "Lay-by",
        isCorrect: false,
      },
      {
        answerText: "End of the restriction zone",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/pedestrianised_street1.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Tram and access only street",
        isCorrect: false,
      },
      {
        answerText: "Pedestrianised street",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_overtaking.png"),
    answerOptions: [
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
      {
        answerText: "No overtaking",
        isCorrect: true,
      },
      {
        answerText: "Two-way traffic",
        isCorrect: false,
      },
      {
        answerText: "No entry",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/height_restriction.png"),
    answerOptions: [
      {
        answerText: "Height restriction",
        isCorrect: true,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Safe height plate",
        isCorrect: false,
      },
      {
        answerText: "Alternative route for high vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_overtaking_for_three_axle_vehicles.png"),
    answerOptions: [
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No overtaking",
        isCorrect: false,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: false,
      },
      {
        answerText:
          "In a tunnel goods vehicles cannot use right-hand lane (by reference to number of axles)",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/mini_roundabout_1.jpg"),
    answerOptions: [
      { answerText: "Dangerous bend ahead", isCorrect: false },
      { answerText: "Mini roundabout ahead", isCorrect: true },
      { answerText: "Loop road ahead", isCorrect: false },
      { answerText: "Roundabout ahead", isCorrect: false },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/keep_left.png"),
    answerOptions: [
      {
        answerText: "Turn left",
        isCorrect: false,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: true,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/keep_right.png"),
    answerOptions: [
      {
        answerText: "Keep right",
        isCorrect: true,
      },
      {
        answerText: "Turn right",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/keep_straight_ahead.png"),
    answerOptions: [
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Straight ahead",
        isCorrect: true,
      },
      {
        answerText: "Two way traffic",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/pass_either_side.png"),
    answerOptions: [
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: true,
      },
      {
        answerText: "Start of a passing lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_left.png"),
    answerOptions: [
      {
        answerText: "Turn left",
        isCorrect: true,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: false,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_left_ahead.png"),
    answerOptions: [
      {
        answerText: "Turn left",
        isCorrect: false,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: true,
      },
      {
        answerText: "Keep left",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_right.png"),
    answerOptions: [
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
      {
        answerText: "Turn right",
        isCorrect: true,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_right_ahead.png"),
    answerOptions: [
      {
        answerText: "Keep right",
        isCorrect: false,
      },
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Turn right",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_per_lane.png"),
    answerOptions: [
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "Speed limit per lane",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry_pedestrians_tramway.png"),
    answerOptions: [
      {
        answerText: "No entry for pedestrians to tramway",
        isCorrect: true,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
      {
        answerText: "test",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/start_of_cycle_track.png"),
    answerOptions: [
      {
        answerText: "Seperate bicycle and pedestrian lanes",
        isCorrect: false,
      },
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "Start of cycle track",
        isCorrect: true,
      },
      {
        answerText: "End of cycle track",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/end_of_cycle_track.png"),
    answerOptions: [
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "End of cycle track",
        isCorrect: true,
      },
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
      {
        answerText: "Start of cycle track",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/go.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/electronic_variable_speed_tunnel.png"),
    answerOptions: [
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: false,
      },
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Electronic variable speed limit sign (tunnel only)",
        isCorrect: true,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/variable_speed_limit.png"),
    answerOptions: [
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: false,
      },
      {
        answerText: "Variable speed limit",
        isCorrect: true,
      },
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/variable_speed_limit.png"),
    answerOptions: [
      {
        answerText:
          "In a tunnel goods vehicles cannot use right-hand lane (by reference to number of axles)",
        isCorrect: true,
      },
      {
        answerText: "Variable speed limit",
        isCorrect: false,
      },
      {
        answerText:
          "No entry to goods vehicles (by reference to number of axles)",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/electronic_periodic_speed_limit.png"),
    answerOptions: [
      {
        answerText:
          "In a tunnel goods vehicles cannot use right-hand lane (by reference to number of axles)",
        isCorrect: false,
      },
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: true,
      },
      {
        answerText: "Electronic periodic speed limit sign at school",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tram_only_street.png"),
    answerOptions: [
      {
        answerText: "Bus only street",
        isCorrect: false,
      },
      {
        answerText: "Tram and access only street",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "Tram only street",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/mobile_vms_displaying_chevrons.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying text message",
        isCorrect: false,
      },
      {
        answerText: "Direction change",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying chevrons",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/mobile_vms_displaying_text_message.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying text message",
        isCorrect: true,
      },
      {
        answerText: "Direction change",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying chevrons",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/vms_chevron_formats.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: false,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
      {
        answerText: "Examples of VMS chevron formats",
        isCorrect: true,
      },
      {
        answerText: "Motorway direction sign",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/overhead_variable_message_sign_vms.png"),
    answerOptions: [
      {
        answerText: "Overhead VMS",
        isCorrect: true,
      },
      {
        answerText: "Mobile VMS displaying text message",
        isCorrect: false,
      },
      {
        answerText: "Direction change",
        isCorrect: false,
      },
      {
        answerText: "Mobile VMS displaying chevrons",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tram_and_access_only_street.png"),
    answerOptions: [
      {
        answerText: "Bus only street",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "Tram and access only street",
        isCorrect: true,
      },
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/bus_only_street.png"),
    answerOptions: [
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Tram only street",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
      {
        answerText: "Bus only street",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/turn_back.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/pedestrians_and_bicycles_only.png"),
    answerOptions: [
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: true,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Seperate bicycle and pedestrian lanes",
        isCorrect: false,
      },
      {
        answerText: "Pedestrianised street",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/seperate_pedestrians_and_bicycles.png"),
    answerOptions: [
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Seperate bicycle and pedestrian lanes",
        isCorrect: true,
      },
      {
        answerText: "Pedestrianised street",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/move_into_left_lane.png"),
    answerOptions: [
      {
        answerText: "Pass either side",
        isCorrect: false,
      },
      {
        answerText: "Move into the left-hand lane",
        isCorrect: true,
      },
      {
        answerText: "Turn left Ahead",
        isCorrect: false,
      },
      {
        answerText: "Turn left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/move_into_right_lane.png"),
    answerOptions: [
      {
        answerText: "Go (lane open)",
        isCorrect: false,
      },
      {
        answerText: "Turn left Ahead",
        isCorrect: false,
      },
      {
        answerText: "Move into the right-hand lane",
        isCorrect: true,
      },
      {
        answerText: "Move into the left-hand lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/go_lane_open.png"),
    answerOptions: [
      {
        answerText: "Go (lane open)",
        isCorrect: true,
      },
      {
        answerText: "Turn left Ahead",
        isCorrect: false,
      },
      {
        answerText: "Move into the right-hand lane",
        isCorrect: false,
      },
      {
        answerText: "Move into the left-hand lane",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/stop_lane_closed.png"),
    answerOptions: [
      {
        answerText: "Move into the left-hand lane",
        isCorrect: false,
      },
      {
        answerText: "Move into the right-hand lane",
        isCorrect: false,
      },
      {
        answerText: "Stop (lane closed)",
        isCorrect: true,
      },
      {
        answerText: "Go (lane open)",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/electronic_periodic_speed_limit_at_school.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Electronic periodic speed limit sign",
        isCorrect: false,
      },
      {
        answerText: "Traffic calming sign",
        isCorrect: false,
      },
      {
        answerText: "Electronic periodic speed limit sign at school",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/stop_circle.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: true,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "School wardens stop sign",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/go_irish.png"),
    answerOptions: [
      {
        answerText: "Yield",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Go or Téigh",
        isCorrect: true,
      },
      {
        answerText: "Traffic signals ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry_to_goods_vehicles_axes.png"),
    answerOptions: [
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText:
          "No entry to goods vehicles (by reference to number of axles)",
        isCorrect: true,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/withflow_buslane_left.png"),
    answerOptions: [
      {
        answerText: "With flow bus lane on right",
        isCorrect: false,
      },
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on left",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/withflow_buslane_right.png"),
    answerOptions: [
      {
        answerText: "With flow bus lane on right",
        isCorrect: true,
      },
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/contraflow_buslane.png"),
    answerOptions: [
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
      {
        answerText: "Contra flow bus lane",
        isCorrect: true,
      },
      {
        answerText: "With flow bus lane on right",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tramlane_on_left.png"),
    answerOptions: [
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on left",
        isCorrect: true,
      },
      {
        answerText: "With flow bus lane on left",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/tramlane_on_right.png"),
    answerOptions: [
      {
        answerText: "Contra flow bus lane",
        isCorrect: false,
      },
      {
        answerText: "Tram lane on right",
        isCorrect: true,
      },
      {
        answerText: "Tram lane on left",
        isCorrect: false,
      },
      {
        answerText: "With flow bus lane on right",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_120kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_30kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_50kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 30kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_60kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 50kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: true,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/speed_limit_80kmh.png"),
    answerOptions: [
      {
        answerText: "Max speed limit 60kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 120kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Max speed limit 80kmh",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/stop.png"),
    answerOptions: [
      {
        answerText: "School wardens stop sign",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
      {
        answerText: "Stop",
        isCorrect: true,
      },
      {
        answerText: "Yield",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/clearway.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: true,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_bicycles.png"),
    answerOptions: [
      {
        answerText: "No ridden or accompanied horses",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "Pedestrians and bicycles only",
        isCorrect: false,
      },
      {
        answerText: "No bicycles",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_vehicle_width.png"),
    answerOptions: [
      {
        answerText: "Height restriction",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: true,
      },
      {
        answerText: "Maximum vehicle length",
        isCorrect: false,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_vehicle_length.png"),
    answerOptions: [
      {
        answerText: "Height restriction",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle length",
        isCorrect: true,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_axle_weight.png"),
    answerOptions: [
      {
        answerText: "Maximum gross weight (traffic management)",
        isCorrect: false,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: true,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/maximum_gross_weight.png"),
    answerOptions: [
      {
        answerText: "Maximum gross weight (traffic management)",
        isCorrect: true,
      },
      {
        answerText: "Maximum vehicle width",
        isCorrect: false,
      },
      {
        answerText: "Maximum axle weight",
        isCorrect: false,
      },
      {
        answerText: "No overtaking for three-axle vehicles",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_ridden_or_accompanied_horses.png"),
    answerOptions: [
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "No ridden or accompanied horses",
        isCorrect: true,
      },
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "No horse carriages",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_horse_carriages.png"),
    answerOptions: [
      {
        answerText: "Turn back",
        isCorrect: false,
      },
      {
        answerText: "No ridden or accompanied horses",
        isCorrect: false,
      },
      {
        answerText: "No bicycles",
        isCorrect: false,
      },
      {
        answerText: "No horse carriages",
        isCorrect: true,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_entry.png"),
    answerOptions: [
      {
        answerText: "Parking prohibited",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: true,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "Turn back",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/max_speed_limit_80.png"),
    answerOptions: [
      {
        answerText: "Maximum speed limit 120kmh",
        isCorrect: false,
      },
      {
        answerText: "Maximum speed limit 100kmh",
        isCorrect: false,
      },
      {
        answerText: "Maximum speed limit 80kmh",
        isCorrect: true,
      },
      {
        answerText: "Maximum speed limit 60kmh",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_parking.png"),
    answerOptions: [
      {
        answerText: "Parking permitted",
        isCorrect: false,
      },
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "Parking prohibited",
        isCorrect: true,
      },
      {
        answerText: "End of the restriction zone",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/school_warden_stop_sign.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Children crossing (in residential area)",
        isCorrect: false,
      },
      {
        answerText: "School wardens stop sign",
        isCorrect: true,
      },
      {
        answerText: "School children crossing ahead",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_left_turn.png"),
    answerOptions: [
      {
        answerText: "No left turn",
        isCorrect: true,
      },
      {
        answerText: "No right turn",
        isCorrect: false,
      },
      {
        answerText: "Turn left ahead",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/yield.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: true,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/yield_in_irish.png"),
    answerOptions: [
      {
        answerText: "Stop",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "Yield",
        isCorrect: true,
      },
      {
        answerText: "Go",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_right_turn.png"),
    answerOptions: [
      {
        answerText: "No left turn",
        isCorrect: false,
      },
      {
        answerText: "No right turn",
        isCorrect: true,
      },
      {
        answerText: "Turn right ahead",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: false,
      },
    ],
  },
  {
    roadSignOption: require("./roadsigns/regulatory_signs/no_straight_ahead.png"),
    answerOptions: [
      {
        answerText: "Clearway",
        isCorrect: false,
      },
      {
        answerText: "No right turn",
        isCorrect: false,
      },
      {
        answerText: "No entry to vehicles",
        isCorrect: false,
      },
      {
        answerText: "No entry or 'No straight ahead'",
        isCorrect: true,
      },
    ],
  },
];

export default allQuestions;
